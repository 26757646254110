<template>
  <div class="notifications-container" v-for="(item, index) in notifications" :key="item.id" :data-index="index" @click="onClickNotification">
    <div class="not-avatar" @click="onClickNotification" :data-index="index">
      <img :src="item.data.logo" alt="" :data-index="index">
    </div>
    <div class="not-details" :data-index="index">
      <div class="not-details-title" :data-index="index">{{ item.data.message }}</div>
      <div class="not-details-date" :data-index="index">{{ item.data.date }}</div>
    </div>
  </div>
</template>

<script>
import eventHub from '../../utils/eventHub'
import Pusher from 'pusher-js'
import useSound from 'vue-use-sound'
import sfx from '../../assets/audio/that-was-quick-606.mp3'

export default {
    name: 'notification-items',
    props: [],
    data () {
        return {
          token: localStorage.getItem('accessToken'),
          user: null,
          pusher: null,
          channel: null,
          notifications: []
        }
    },
    setup () {
      const [play] = useSound(sfx, {
        soundEnabled: true
      })
      return {
        play
      }
    },
    watch: {
      notifications (newValue, oldValue) {
        eventHub.$emit('set-notifications', this.notifications)
      },
      user (newValue, oldValue) {
        if (newValue) {
          this.getNotifications()
          // Pusher.logToConsole = true
          this.pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
            cluster: process.env.VUE_APP_PUSHER_CLUSTER,
            encrypted: true,
            forceTLS: true
          })
          this.channel = this.pusher.subscribe(`sfchannel-u${this.user.id}`)
          this.channel.bind('notifications', (data) => {
            if (data) {
              const newNotification = this.formatToNotification(data)
              this.notifications.push(newNotification)
              this.notifications = [...this.notifications]
              this.play()
            }
          })
        }
      }
    },
    created () {
      eventHub.$emit('set-notifications', this.notifications)
      eventHub.$on('user-data', (data) => {
        if (data?.user) {
          this.user = data.user
        }
      })
      if (localStorage.getItem('userData')) {
        const user = JSON.parse(localStorage.getItem('userData'))
        this.user = user
      }
    },
    mounted () {
      this.token = localStorage.getItem('accessToken')
    },
    unmounted () {
      if (this.channel) this.channel.unbind('notifications')
      if (this.pusher) this.pusher.unsubscribe(`sfchannel-u${this.user.id}`)
    },
    methods: {
      onClickNotification (event) {
        const index = event?.target?.getAttribute('data-index')
        if (index) {
          const notification = this.notifications[index]
          if (notification) {
            /* window.open(notification.link, '_blank') */
            this.markAsRead(notification.id).then(resp => {
              if (resp) {
                this.getNotifications()
              }
            }).catch(err => {
              console.log('................. Error On Update Read: ', err)
            })
          }
        }
      },
      markAsRead (notificationID) {
        const config = {
          method: 'put',
          url: process.env.VUE_APP_API + `/notifications/read/${notificationID}`,
          data: {}
      }
        return this.axios(config)
      },
      getNotifications () {
        this.axios
          .get(process.env.VUE_APP_API + `/notifications/list?user_id=${this.user.id}&unread=true`)
          .then((response) => {
            if (response?.data?.data) {
              this.notifications = response?.data?.data
              this.notifications = this.notifications.filter(notif => {
                return !notif.is_read && notif.user === this.user.id
              })
            }
          }).catch(err => {
            if (err?.response?.data?.message === 'Unauthenticated.') {
              eventHub.$emit(this.modalName, false)
              eventHub.$emit('logout', true)
            }
          })
      },
      formatToNotification (data) {
        return {
          ...data,
          id: data.message.id,
          is_read: false,
          link: data.url,
          data: {
            date: data.message.date,
            logo: data.message.logo,
            message: data.message.message
          }
        }
      }
    }
}
</script>

<style lang="scss" scoped>
  .notifications-container {
    margin: 7px 0;
    background-color: #f4f3f9;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    padding: 4px 0;
    cursor: pointer;
  }
  .not-avatar {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  .not-avatar img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }
  .not-details {
    flex: 1;
    min-width: 10vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .not-details-title {
    font-size: 12px;
    font-weight: bold;
  }
  .not-details-date {
    font-size: 10px;
  }
</style>
