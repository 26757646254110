import { createRouter, createWebHistory } from 'vue-router'

const isUserAuthenticated = () => {
  let isAuthenticated = false
  if (localStorage.getItem('accessToken')) {
    isAuthenticated = true
  }
  return isAuthenticated
}

const routerGuard = (to, from, next) => {
  const isAuthenticated = isUserAuthenticated()
  isAuthenticated ? next() : next('/login')
}

const redirectOnAuthenticated = (to, from, next) => {
  const isAuthenticated = isUserAuthenticated()
  isAuthenticated ? next('/dashboard') : next()
}

const routes = [
  {
    path: '/',
    name: 'home',
    beforeEnter: redirectOnAuthenticated,
    component: () => import(/* webpackChunkName: "home" */ '../views/Home2.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import(/* webpackChunkName: "signup" */ '../views/Signup.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/emailverify/:id',
    name: 'verified',
    component: () => import(/* webpackChunkName: "verified" */ '../views/Verifed.vue')
  },
  {
    path: '/verify',
    name: 'verify',
    component: () => import(/* webpackChunkName: "verify" */ '../views/Verify.vue')
  },
  {
    path: '/forgot',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "forgot" */ '../views/forgot-password/Forgot.vue')
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "reset" */ '../views/Reset.vue')
  },
  {
    path: '/password-updated',
    name: 'password-updated',
    component: () => import(/* webpackChunkName: "updated" */ '../views/Updated.vue')
  },
  {
    path: '/mailrender/:token',
    name: 'mail-render',
    component: () => import(/* webpackChunkName: "mail-render" */ '../views/mail-render/MailRender.vue')
  },
  {
    path: '/mailrender/:token/print',
    name: 'mail-render-print',
    component: () => import(/* webpackChunkName: "mail-render-print" */ '../views/mail-render/MailRenderPrinter.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    beforeEnter: routerGuard,
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
