<template>
    <div class="form-group">
        <label class="form-label mt-1" for="calendar_name">Name</label>
        <input class="form-control" name="calendar_name" id="calendar_name" type="text" v-model="value.name" />
        <label class="form-label mt-3" for="calendar_description">Description</label>
        <textarea class="form-control" name="calendar_description" id="calendar_description" type="text"
            v-model="value.description" rows="10"></textarea>
        <a class="btn btn-outline-primary mt-4" @click="save">Save</a>
        <a class="btn btn-outline-danger mt-4 ml-4" @click="deleteCalendar">
            <i class="fas fa-trash-alt mr-2" aria-hidden="true"></i> Delete
        </a>
        <a class="btn btn-outline-secondary mt-4 ml-4 float-right" @click="$emit('cancel')">
            <i class="fas fa-window-close mr-2"></i> Cancel
        </a>
    </div>
</template>
<style scoped>
label {
    font-size: 8px !important;
}

input,
textarea {
    font-size: 14px;
}
</style>
<script>
export default {
    props: {
        modelValue: Object
    },
    emits: ['update:modelValue', 'save'],
    data () {
        return {
            name: '',
            description: ''
        }
    },
    computed: {
        value: {
            get: function () {
                return this.modelValue
            },
            set: function (value) {
                this.$emit('update:modelValue', value)
            }
        }
    },
    methods: {
        save () {
            if (this.value.id) {
                this.saveCalendar()
            } else {
                this.createCalendar()
            }
        },
        deleteCalendar () {
            console.log('delete')
            const config = {
                method: 'DELETE',
                url: process.env.VUE_APP_API + `/calendar/${this.value.id}`
            }
            this.axios(config)
                .then((response) => {
                    this.$emit('save', response.data)
                })
                .catch((err) => console.log(err))
        },
        saveCalendar () {
            const config = {
                method: 'PUT',
                url: process.env.VUE_APP_API + `/calendar/${this.value.id}`,
                params: { ...this.value }
            }
            this.axios(config)
                .then((response) => {
                    this.$emit('save', response.data)
                })
                .catch((err) => console.log(err))
        },
        createCalendar () {
            const config = {
                method: 'POST',
                url: process.env.VUE_APP_API + '/calendar',
                params: { ...this.value }
            }
            this.axios(config)
                .then((response) => {
                    this.$emit('save', response.data)
                })
                .catch((err) => console.log(err))
        }
    }
}
</script>
<style lang="scss" scoped>
.btn {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.3);
}

.btn.selected {
    background-color: $primary !important;
    border-color: $primary;
    color: $light;
}

.btn:hover:not(.selected) {
    background-color: #e0e1ed;
}

.instance-errors {
    width: 100%;
    font-size: 10px;
    color: #EF6565;
    padding: 5px 8px 0;
    display: none;
}

.custom-input {
    height: 14px;
    width: 14px;
}

label {
    color: $secondary-gray;
    font-size: 18px !important;
    padding-top: 12px !important;
}
</style>
