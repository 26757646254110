<template>
  <div class="popover-wrapper" v-show="show">
    <div>
      <ColorPicker
        :id="name"
        default-format="hex"
        :color="color && color !== '' ? color : '#c31a1a'"
        @color-change="updateColor"
      >
        <template #copy-button>
          <span class="sr-only">Copy color</span>
          <svg
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
          >
            <path
              d="M5 0v2H1v13h12v-3h-1v2H2V5h10v3h1V2H9V0zm1 1h2v2h3v1H3V3h3z"
              fill="currentColor"
            />

            <path
              d="M10 7v2h5v2h-5v2l-3-3zM3 6h5v1H3zm0 2h3v1H3zm0 2h3v1H3zm0 2h5v1H3z"
              fill="currentColor"
            />
          </svg>
        </template>
        <template #format-switch-button>
          <span class="sr-only">Switch format</span>

          <svg
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="15"
            viewBox="0 0 16 15"
          >
            <path
              d="M8 15l5-5-1-1-4 2-4-2-1 1zm4-9l1-1-5-5-5 5 1 1 4-2z"
              fill="currentColor"
            />
          </svg>
        </template>
      </ColorPicker>
    </div>
    <div class="cp-footer">
      <button class="btn" @click="onClose">Cancel</button>
      <button class="btn btn-primary" @click="onChooseColor">Choose</button>
    </div>
  </div>
</template>

<script>
import { ColorPicker } from 'vue-accessible-color-picker'
import eventHub from '../../utils/eventHub'

export default {
  name: 'color-picker-popover',
  props: ['show', 'name', 'color'],
  components: {
    ColorPicker
  },
  data () {
    return {
      initalColor: null
    }
  },
  watch: {
    show (newValue, oldValue) {
      if (newValue) {
        this.initialColor = this.color
      }
    }
  },
  mounted () {
    this.initalColor = this.color
  },
  methods: {
    onClose () {
      eventHub.$emit('close-picker', this.name)
      eventHub.$emit('picked-color', {
        prop: this.name,
        color: this.initalColor
      })
    },
    updateColor (eventData) {
      eventHub.$emit('picked-color', {
        prop: this.name,
        color: eventData.cssColor
      })
    },
    onChooseColor () {
      this.initalColor = this.color
      eventHub.$emit('picked-color', {
        prop: this.name,
        color: this.color
      })
      eventHub.$emit('close-picker', this.name)
    }
  }
}
</script>

<style lang="scss" scoped>
.popover-wrapper {
  width: 310px;
  position: absolute;
  z-index: 99999;
  background-color: #fff;
  top: 30px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  left: 10vw;
  transform: scale(0.8);
  -webkit-box-shadow: 5px 5px 7px 0px rgba(0,0,0,0.33);
  box-shadow: 5px 5px 7px 0px rgba(0,0,0,0.33);

  .vacp-color-picker {
    margin: 12px auto 6px;
  }
  .cp-footer {
    margin: 0 12px 12px;
    text-align: right;

    .btn-primary {
      margin-left: 10px;
    }
    .btn {
      padding: 0.2rem 1rem;
    }
  }
}
</style>
