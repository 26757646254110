<template>
  <div class="wrapper" id="surefooted-wrapper" :style="`background-color: ${colorsCtrl.full_bg}`">
    <div class="container" id="container-1" style="display: block;">
      <div class="header">
        <div class="header-left" :style="`color: ${colorsCtrl.high_h1_fg}`">
          <div class="eventtitle">
            The Welsh Prince
          </div>
          <div class="days">
            4 Days
          </div>
        </div>
        <div class="header-right" id="title-offer-price-wrapper" :style="`color: ${colorsCtrl.p_fg}`">
          <span id="title-offer-price">₤660</span> <span>/ person</span>
        </div>
      </div>
      <div id="step-container">
        <div class="calendar-wrapper" :style="`background-color: ${colorsCtrl.item_bg}`">
          <div class="row">
            <div id="calendar-dates-selected" class="calendar-dates-selected" selected-date="true">
              <div class="calendar-notice" :style="`color: ${colorsCtrl.p_fg}`">Dates selected: </div>
              <div class="selected-dates-wrapper" id="selected-dates-wrapper">
                <div class="dates-icon" :style="`fill: ${colorsCtrl.p_fg_sec}`">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.945 18.945">
                    <g id="calendar_1_" opacity="0.4">
                      <path id="Caminho_2" data-name="Caminho 2"
                        d="M17.367,1.776H14.8V.592a.592.592,0,0,0-1.184,0V1.776H10.065V.592a.592.592,0,0,0-1.184,0V1.776H5.328V.592a.592.592,0,0,0-1.184,0V1.776H1.579A1.578,1.578,0,0,0,0,3.355V17.366a1.579,1.579,0,0,0,1.579,1.579H17.367a1.578,1.578,0,0,0,1.578-1.579V3.355A1.578,1.578,0,0,0,17.367,1.776Zm.394,15.59a.4.4,0,0,1-.394.395H1.579a.4.4,0,0,1-.395-.395V3.355a.4.4,0,0,1,.395-.394H4.144V4.144a.592.592,0,0,0,1.184,0V2.96H8.881V4.144a.592.592,0,0,0,1.184,0V2.96h3.552V4.144a.592.592,0,0,0,1.184,0V2.96h2.566a.4.4,0,0,1,.394.394Z" />
                      <rect id="Retângulo_87" data-name="Retângulo 87" width="3" height="2" transform="translate(4 7)" />
                      <rect id="Retângulo_88" data-name="Retângulo 88" width="3" height="2" transform="translate(4 10)" />
                      <rect id="Retângulo_89" data-name="Retângulo 89" width="3" height="2" transform="translate(4 13)" />
                      <rect id="Retângulo_90" data-name="Retângulo 90" width="3" height="2" transform="translate(8 13)" />
                      <rect id="Retângulo_91" data-name="Retângulo 91" width="3" height="2" transform="translate(8 10)" />
                      <rect id="Retângulo_92" data-name="Retângulo 92" width="3" height="2" transform="translate(8 7)" />
                      <rect id="Retângulo_93" data-name="Retângulo 93" width="3" height="2"
                        transform="translate(12 13)" />
                      <rect id="Retângulo_94" data-name="Retângulo 94" width="3" height="2"
                        transform="translate(12 10)" />
                      <rect id="Retângulo_95" data-name="Retângulo 95" width="3" height="2" transform="translate(12 7)" />
                    </g>
                  </svg>
                </div>
                <div class="dates-text" :style="`color: ${colorsCtrl.p_fg}`">
                  Wed <span>14</span>TH July
                  -
                  Sun <span>18</span>TH July
                </div>
                <div class="dates-avail-label">
                  <span class="badge avail-badge"
                    :style="`background-color: ${colorsCtrl.high_selected_date_bg}; color: ${colorsCtrl.high_selected_date_fg}`">
                    Available
                  </span>
                </div>
              </div>
              <div class="clear-dates-wrapper" id="clear-dates-wrapper" :style="`color: ${colorsCtrl.p_fg}`">
                <div class="clear-dates-btn">Clear dates</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div id="calendar" class=" NavShow-true DateTimeShow-true DateTimeFormat-mmm, yyyy">
              <div class="cld-main cld-main-left">
                <div class="cld-datetime">
                  <div class=" cld-rwd cld-nav" :style="`color: ${colorsCtrl.p_fg_sec}`">
                    <div class="cld-icon" :style="`border: 1px solid ${colorsCtrl.p_fg_sec}`">❮</div>
                  </div>
                  <div class=" today" :style="`color: ${colorsCtrl.p_fg_sec}`">July 2021</div>
                </div>
                <ul class="cld-labels" :style="`color: ${colorsCtrl.p_fg_sec}`">
                  <li class="cld-label">Sun</li>
                  <li class="cld-label">Mon</li>
                  <li class="cld-label">Tue</li>
                  <li class="cld-label">Wed</li>
                  <li class="cld-label">Thu</li>
                  <li class="cld-label">Fri</li>
                  <li class="cld-label">Sat</li>
                </ul>
                <ul class="cld-days" :style="`color: ${colorsCtrl.p_fg}`">
                  <li class="cld-day currMonth disableDay" month="6" day="1">
                    <p class="cld-number">01</p>
                  </li>
                  <li class="cld-day currMonth disableDay" month="6" day="2">
                    <p class="cld-number">02</p>
                  </li>
                  <li class="cld-day currMonth disableDay" month="6" day="3">
                    <p class="cld-number">03</p>
                  </li>
                  <li class="cld-day currMonth disableDay" month="6" day="4">
                    <p class="cld-number">04</p>
                  </li>
                  <li class="cld-day currMonth" month="6" day="5">
                    <p class="cld-number">05</p>
                  </li>
                  <li class="cld-day currMonth disableDay" month="6" day="6">
                    <p class="cld-number">06</p>
                  </li>
                  <li class="cld-day currMonth unavailableDay" :style="`color: ${colorsCtrl.p_fg_sec}`"
                    unavail-offer="6-1" month="6" day="7">
                    <p class="cld-number">07</p>
                  </li>
                  <li class="cld-day currMonth unavailableDay" :style="`color: ${colorsCtrl.p_fg_sec}`"
                    unavail-offer="6-1" month="6" day="8">
                    <p class="cld-number">08</p>
                  </li>
                  <li class="cld-day currMonth unavailableDay" :style="`color: ${colorsCtrl.p_fg_sec}`"
                    unavail-offer="6-1" month="6" day="9">
                    <p class="cld-number">09</p>
                  </li>
                  <li class="cld-day currMonth unavailableDay" :style="`color: ${colorsCtrl.p_fg_sec}`"
                    unavail-offer="6-1" month="6" day="10">
                    <p class="cld-number">10</p>
                  </li>
                  <li class="cld-day currMonth unavailableDay" :style="`color: ${colorsCtrl.p_fg_sec}`"
                    unavail-offer="6-1" month="6" day="11">
                    <p class="cld-number">11</p>
                  </li>
                  <li class="cld-day currMonth disableDay" month="6" day="12">
                    <p class="cld-number">12</p>
                  </li>
                  <li class="cld-day currMonth disableDay" month="6" day="13">
                    <p class="cld-number">13</p>
                  </li>
                  <li class="cld-day currMonth selected-offer-start"
                    :style="`color: ${colorsCtrl.high_selected_date_fg}; background-color: ${colorsCtrl.high_selected_date_bg}`"
                    month="6" day="14" avail-offer="6-2">
                    <p class="cld-number">14</p>
                  </li>
                  <li class="cld-day currMonth selected-offer"
                    :style="`color: ${colorsCtrl.high_selected_date_fg}; background-color: ${colorsCtrl.high_selected_date_bg}`"
                    month="6" day="15" avail-offer="6-2">
                    <p class="cld-number">15</p>
                  </li>
                  <li class="cld-day currMonth selected-offer"
                    :style="`color: ${colorsCtrl.high_selected_date_fg}; background-color: ${colorsCtrl.high_selected_date_bg}`"
                    month="6" day="16" avail-offer="6-2">
                    <p class="cld-number">16</p>
                  </li>
                  <li class="cld-day currMonth selected-offer"
                    :style="`color: ${colorsCtrl.high_selected_date_fg}; background-color: ${colorsCtrl.high_selected_date_bg}`"
                    month="6" day="17" avail-offer="6-2">
                    <p class="cld-number">17</p>
                  </li>
                  <li class="cld-day currMonth selected-offer-last"
                    :style="`background-color: ${colorsCtrl.high_selected_date_bg}; color: ${colorsCtrl.high_selected_date_fg}`"
                    month="6" day="18" avail-offer="6-2">
                    <p class="cld-number">18</p>
                  </li>
                  <li class="cld-day currMonth disableDay" month="6" day="19">
                    <p class="cld-number">19</p>
                  </li>
                  <li class="cld-day currMonth unavailableDay" :style="`color: ${colorsCtrl.p_fg_sec}`"
                    unavail-offer="6-3" month="6" day="20">
                    <p class="cld-number">20</p>
                  </li>
                  <li class="cld-day currMonth unavailableDay" :style="`color: ${colorsCtrl.p_fg_sec}`"
                    unavail-offer="6-3" month="6" day="21">
                    <p class="cld-number">21</p>
                  </li>
                  <li class="cld-day currMonth unavailableDay" unavail-offer="6-3" month="6" day="22">
                    <p class="cld-number">22</p>
                  </li>
                  <li class="cld-day currMonth unavailableDay" :style="`color: ${colorsCtrl.p_fg_sec}`"
                    unavail-offer="6-3" month="6" day="23">
                    <p class="cld-number">23</p>
                  </li>
                  <li class="cld-day currMonth unavailableDay" :style="`color: ${colorsCtrl.p_fg_sec}`"
                    unavail-offer="6-3" month="6" day="24">
                    <p class="cld-number">24</p>
                  </li>
                  <li class="cld-day currMonth disableDay" month="6" day="25">
                    <p class="cld-number">25</p>
                  </li>
                  <li class="cld-day currMonth disableDay" month="6" day="26">
                    <p class="cld-number">26</p>
                  </li>
                  <li class="cld-day currMonth disableDay" month="6" day="27">
                    <p class="cld-number">27</p>
                  </li>
                  <li class="cld-day currMonth disableDay" month="6" day="28">
                    <p class="cld-number">28</p>
                  </li>
                  <li class="cld-day currMonth disableDay" month="6" day="29">
                    <p class="cld-number">29</p>
                  </li>
                  <li class="cld-day currMonth disableDay" month="6" day="30">
                    <p class="cld-number">30</p>
                  </li>
                  <li class="cld-day currMonth disableDay" month="6" day="31">
                    <p class="cld-number">31</p>
                  </li>
                </ul>
              </div>
              <div class="cld-main cld-main-right">
                <div class="cld-datetime">
                  <div class=" today" :style="`color: ${colorsCtrl.p_fg_sec}`">August 2021</div>
                  <div class=" cld-fwd cld-nav" :style="`color: ${colorsCtrl.p_fg_sec}`">
                    <div class="cld-icon" :style="`border: 1px solid ${colorsCtrl.p_fg_sec}`">❯</div>
                  </div>
                </div>
                <ul class="cld-labels" :style="`color: ${colorsCtrl.p_fg_sec}`">
                  <li class="cld-label">Sun</li>
                  <li class="cld-label">Mon</li>
                  <li class="cld-label">Tue</li>
                  <li class="cld-label">Wed</li>
                  <li class="cld-label">Thu</li>
                  <li class="cld-label">Fri</li>
                  <li class="cld-label">Sat</li>
                </ul>
                <ul class="cld-days" :style="`color: ${colorsCtrl.p_fg}`">
                  <li class="cld-day currMonth disableDay" month="7" day="1">
                    <p class="cld-number">01</p>
                  </li>
                  <li class="cld-day currMonth disableDay" month="7" day="2">
                    <p class="cld-number">02</p>
                  </li>
                  <li class="cld-day currMonth" month="7" day="3" avail-offer="7-0">
                    <p class="cld-number">03</p>
                  </li>
                  <li class="cld-day currMonth" month="7" day="4" avail-offer="7-0">
                    <p class="cld-number">04</p>
                  </li>
                  <li class="cld-day currMonth" month="7" day="5" avail-offer="7-0">
                    <p class="cld-number">05</p>
                  </li>
                  <li class="cld-day currMonth" month="7" day="6" avail-offer="7-0">
                    <p class="cld-number">06</p>
                  </li>
                  <li class="cld-day currMonth" month="7" day="7" avail-offer="7-0">
                    <p class="cld-number">07</p>
                  </li>
                  <li class="cld-day currMonth disableDay" month="7" day="8">
                    <p class="cld-number">08</p>
                  </li>
                  <li class="cld-day currMonth unavailableDay" :style="`color: ${colorsCtrl.p_fg_sec}`"
                    unavail-offer="7-1" month="7" day="9">
                    <p class="cld-number">09</p>
                  </li>
                  <li class="cld-day currMonth unavailableDay" :style="`color: ${colorsCtrl.p_fg_sec}`"
                    unavail-offer="7-1" month="7" day="10">
                    <p class="cld-number">10</p>
                  </li>
                  <li class="cld-day currMonth unavailableDay" :style="`color: ${colorsCtrl.p_fg_sec}`"
                    unavail-offer="7-1" month="7" day="11">
                    <p class="cld-number">11</p>
                  </li>
                  <li class="cld-day currMonth unavailableDay" :style="`color: ${colorsCtrl.p_fg_sec}`"
                    unavail-offer="7-1" month="7" day="12">
                    <p class="cld-number">12</p>
                  </li>
                  <li class="cld-day currMonth unavailableDay" :style="`color: ${colorsCtrl.p_fg_sec}`"
                    unavail-offer="7-1" month="7" day="13">
                    <p class="cld-number">13</p>
                  </li>
                  <li class="cld-day currMonth disableDay" month="7" day="14">
                    <p class="cld-number">14</p>
                  </li>
                  <li class="cld-day currMonth" month="7" day="15" avail-offer="7-2">
                    <p class="cld-number">15</p>
                  </li>
                  <li class="cld-day currMonth" month="7" day="16" avail-offer="7-2">
                    <p class="cld-number">16</p>
                  </li>
                  <li class="cld-day currMonth" month="7" day="17" avail-offer="7-2">
                    <p class="cld-number">17</p>
                  </li>
                  <li class="cld-day currMonth" month="7" day="18" avail-offer="7-2">
                    <p class="cld-number">18</p>
                  </li>
                  <li class="cld-day currMonth" month="7" day="19" avail-offer="7-2">
                    <p class="cld-number">19</p>
                  </li>
                  <li class="cld-day currMonth disableDay" month="7" day="20">
                    <p class="cld-number">20</p>
                  </li>
                  <li class="cld-day currMonth disableDay" month="7" day="21">
                    <p class="cld-number">21</p>
                  </li>
                  <li class="cld-day currMonth unavailableDay" :style="`color: ${colorsCtrl.p_fg_sec}`"
                    unavail-offer="7-3" month="7" day="22">
                    <p class="cld-number">22</p>
                  </li>
                  <li class="cld-day currMonth unavailableDay" unavail-offer="7-3" month="7" day="23">
                    <p class="cld-number">23</p>
                  </li>
                  <li class="cld-day currMonth unavailableDay" :style="`color: ${colorsCtrl.p_fg_sec}`"
                    unavail-offer="7-3" month="7" day="24">
                    <p class="cld-number">24</p>
                  </li>
                  <li class="cld-day currMonth unavailableDay" :style="`color: ${colorsCtrl.p_fg_sec}`"
                    unavail-offer="7-3" month="7" day="25">
                    <p class="cld-number">25</p>
                  </li>
                  <li class="cld-day currMonth unavailableDay" :style="`color: ${colorsCtrl.p_fg_sec}`"
                    unavail-offer="7-3" month="7" day="26">
                    <p class="cld-number">26</p>
                  </li>
                  <li class="cld-day currMonth disableDay" month="7" day="27">
                    <p class="cld-number">27</p>
                  </li>
                  <li class="cld-day currMonth disableDay" month="7" day="28">
                    <p class="cld-number">28</p>
                  </li>
                  <li class="cld-day currMonth disableDay" month="7" day="29">
                    <p class="cld-number">29</p>
                  </li>
                  <li class="cld-day currMonth disableDay" month="7" day="30">
                    <p class="cld-number">30</p>
                  </li>
                  <li class="cld-day currMonth disableDay" month="7" day="31">
                    <p class="cld-number">31</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div id="calendar-book-now" style="display: flex;">
              <button @mouseout="onButtonOut" @mouseover="onButtonHover" type="button" id="calendar-book-now-btn"
                name="book-now"
                :style="`color: ${colorsCtrl.high_but_fg};background-color: ${colorsCtrl.high_but_bg}; border: 2px solid ${colorsCtrl.high_but_fg}`">
                Book Now →
              </button>
              <!-- <ul id="example-1">
                <li v-for="item in daysOne" :key="item.day">
                  {{ item.day }}
                </li>
              </ul>
              <ul id="example-1">
                <li v-for="item in daysTwo" :key="item.day">
                  {{ item.day }}
                </li>
              </ul> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'open-calendar-preview',
  data () {
    const daysOne = []
    const daysTwo = []
    const firstMonth = 1
    const secondMonth = 2
    this.daysToCalendar(firstMonth, secondMonth, daysOne, daysTwo)
    return {
      daysOne: daysOne,
      daysTwo: daysTwo,
      test: '',
      monthOne: firstMonth,
      monthTwo: secondMonth
    }
  },
  props: ['colorsCtrl'],
  methods: {
    onButtonHover (event) {
      event.target.style.color = this.colorsCtrl.high_but_fg_hover
      event.target.style.backgroundColor = this.colorsCtrl.high_but_bg_hover
    },
    onButtonOut (event) {
      event.target.style.color = this.colorsCtrl.high_but_fg
      event.target.style.backgroundColor = this.colorsCtrl.high_but_bg
    },
    daysToCalendar (firstMonth, secondMonth, daysOne, daysTwo) {
      const date = new Date()
      const year = date.getFullYear()
      this.getFirstMonthDays(firstMonth, year, daysOne)
      this.getSecondMonthDays(secondMonth, year, daysTwo)
    },
    getFirstMonthDays (month, year, daysOne) {
      month--

      var date = new Date(year, month, 1)

      while (date.getMonth() === month) {
        daysOne.push({
          day: date.getDate(),
          month: month + 1
        })
        date.setDate(date.getDate() + 1)
      }
      return daysOne
    },
    getSecondMonthDays (month, year, daysTwo) {
      month--

      var date = new Date(year, month, 1)

      while (date.getMonth() === month) {
        daysTwo.push({
          day: date.getDate(),
          month: month + 1
        })
        date.setDate(date.getDate() + 1)
      }

      return daysTwo
    }
  }
}
</script>

<style lang="scss" scoped>
*.row {
  margin-left: 0;
  margin-right: 0;
}

.wrapper {
  padding: 2% 0;
  height: auto;
  width: 100%;

  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
}

.header {
  width: 100%;
  min-height: 20%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 2%;

  .eventtitle {
    font-size: 2.2em;
    font-weight: 700;
  }

  .days {
    flex: 1;
    font-size: 2.2em;
    font-weight: 300;
    padding-left: 10px;
    font-family: "Libre Franklin";
  }
}

.header-left {
  flex: 1;
  display: inline-flex;
}

.header-right {
  flex: 1;
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-end;

  #title-offer-price {
    font-size: 1.4rem;
    font-weight: 500;
  }

  span:nth-child(2) {
    font-size: 1rem;
    font-weight: 300;
    margin-left: 4px;
    margin-bottom: 2px;
  }
}

.calendar-wrapper {
  flex: 1;
  padding: 2% 2%;
  border-radius: 10px;
  box-shadow: 0px 3px 10px #d5d7da;
  margin-top: 2%;
}

.calendar-wrapper .row #calendar-book-now {
  width: 100%;
  justify-content: flex-end;
  display: inline-flex;
}

.calendar-wrapper .row #calendar-book-now-btn {
  height: 60px;
  width: 160px;
  position: relative;
  align-items: center;
  overflow: hidden;
  padding: 12px 12px;
  border-radius: 10px;
  border: 2px solid #444446;
  background-color: #444446;
  color: #fff;
  letter-spacing: 1px;
  font-size: 1.1rem;
  transition: 0.1s;
  cursor: pointer;
}

.calendar-dates-selected {
  display: inline-flex;
  width: 100%;
  margin-bottom: 25px;
  min-height: 33px;
}

.calendar-dates-selected .calendar-notice {
  font-size: 17px;
  display: flex;
  align-items: center;
}

.calendar-dates-selected .selected-dates-wrapper {
  display: flex;
  align-items: center;
  margin-left: 22px;
  border: 1px solid #e7e8ed;
  border-radius: 5px;
  padding: 5px 10px;
}

.calendar-dates-selected .selected-dates-wrapper .dates-text {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.2px;
  margin-left: 10px;
}

.calendar-dates-selected .selected-dates-wrapper .dates-text span {
  font-size: 14px;
}

.calendar-dates-selected .selected-dates-wrapper .dates-icon {
  width: 20px;
}

.calendar-dates-selected .selected-dates-wrapper .badge {
  font-size: 9px;
  padding: 3px 6px;
  margin-left: 10px;
  border-radius: 5px;
  color: #fff;
  text-transform: uppercase;
}

.calendar-dates-selected .selected-dates-wrapper .avail-badge {
  background-color: #59c154;
}

.calendar-dates-selected .selected-dates-wrapper .unavail-badge {
  background-color: #ff7a7a;
}

.calendar-dates-selected .clear-dates-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.calendar-dates-selected .clear-dates-wrapper .clear-dates-btn {
  text-decoration: underline;
  cursor: pointer;
}

#calendar {
  display: inline-flex;
  width: 100%;
}

.cld-main {
  width: 330px;
}

.cld-main-left {
  flex: 1;
}

.cld-main-right {
  flex: 1;
  margin-left: 8%;
}

.cld-main a {
  color: #333;
  font-weight: bold;
}

.cld-datetime {
  position: relative;
  width: 66%;
  min-width: 100%;
  min-height: 34px;
  margin: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.cld-datetime .today {
  position: relative;
  float: left;
  width: calc(100% - 50px);
  margin: auto;
  text-align: center;
  font-size: 20px;
  color: #707070;
}

.cld-nav {
  position: relative;
  width: 34px;
  height: 34px;
  margin-top: 2px;
}

.cld-nav:hover {
  cursor: pointer;
}

.cld-nav:hover svg {
  fill: #666;
}

.cld-rwd {
  float: left;
}

.cld-fwd {
  float: right;
}

.cld-icon {
  width: 34px;
  height: 34px;
  border: 1px solid #d6d6d6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-right: 15px;
  align-items: center;
  font-size: 1.35rem;
  padding-right: 1.5px;
}

.cld-icon:hover {
  background: #f1f0f0;
}

.cld-labels,
.cld-days {
  padding-left: 0;
}

.cld-labels .cld-label {
  font-size: 11px;
  //   color: #7e84a3;
  text-transform: uppercase;
}

.cld-label,
.cld-day {
  display: inline-block;
  width: 14.28%;
  text-align: center;
  position: relative;
}

.cld-day.today .cld-number {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: #666;
  color: #fff;
}

.cld-day.disableDay {
  opacity: 0.5;
  text-decoration: line-through;
}

.cld-day.unavailableDay {
  opacity: 0.7;
  color: #000;
}

.cld-day.nextMonth,
.cld-day.prevMonth {
  visibility: hidden;
  opacity: 0.33;
}

.cld-number {
  position: relative;
  margin: 5px;
  padding: 5px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid transparent;
}

.cld-day.currMonth:not(.selected-offer):not(.selected-offer-start):not(.selected-offer-last) .cld-title {
  position: absolute;
  z-index: 5;
  display: none;
  top: 30px;
  left: 0;
  padding: 5px 10px;
  background: #fff;
  white-space: nowrap;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 12px;
}

.cld-number:hover .cld-title {
  display: block;
}

.cld-title::before {
  content: "";
  position: absolute;
  top: -7.5px;
  left: 7.5px;
  width: 0;
  height: 0;
  border-left: 7.5px solid transparent;
  border-right: 7.5px solid transparent;

  border-bottom: 7.5px solid #ccc;
}

.cld-number.eventday {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  border: 1px solid #999;
}

.cld-number.eventday:hover {
  cursor: pointer;
  background: #eee;
}

.today .cld-number.eventday:hover {
  background: #888;
}

.selected-offer-last {
  color: #fff;
  background-color: #59c154;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
}

.selected-offer {
  background-color: #59c154;
  color: #fff;
}

.selected-offer-start {
  color: #fff;
  background-color: #59c154;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
}
</style>
