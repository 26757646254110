<template>
  <modal ref="settings-modal" :show="openModal" :modalName="modalName">
    <template v-slot:header>
      <h1>Account Settings</h1>
    </template>
    <template v-slot:body>
      <form>
        <div class="form-row mb-3">
          <div class="form-group col-md-6">
            <customInput type="text"
              v-model="v$.first_name.$model"
              :validator="v$"
              :errors="v$.first_name.$errors"
              name="first_name"
              label="First Name*"
              :modalName="modalName"
              placeholder="ex: John"
              saveBtn="true">
            </customInput>
          </div>
          <div class="form-group col-md-6">
            <customInput type="text"
              v-model="v$.last_name.$model"
              :validator="v$"
              :errors="v$.last_name.$errors"
              name="last_name" label="Last Name*"
              :modalName="modalName"
              placeholder="ex: Doe"
              saveBtn="true">
            </customInput>
          </div>
        </div>
          <!-- <customInput type="email"
            v-model="v$.email.$model"
            :validator="v$"
            :errors="v$.email.$errors"
            name="email" label="Email address*"
            :modalName="modalName"
            placeholder="ex: john@example.com"
            saveBtn="true">
          </customInput> -->
          <div class="form-group mt--1 mb-3">
          <label class="auth-labels text-uppercase small pl-1">
            Email address*
          </label>
          <div class="sr-custom-input"
            :class="{ error: v$.email.$errors }">
            <input type="email" :disabled="true"
              class="form-control"
              name="email"
              v-model="v$.email.$model"
              @input="v$.confirmation_passwd.$touch"
              placeholder="ex: john@example.com">
            <i class="fas fa-check"></i>
            <div class="input-errors" v-for="error of v$.confirmation_passwd.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </div>

        <div class="form-group mt--1 mb-3">
          <div class="labels-wrapper">
              <label class="auth-labels text-uppercase small pl-1" for="passwd">
                Password*
              </label>
              <div class="save-wrapper" v-if="valueChanged">
                  <i class="far fa-save" @click="onSavePassword"></i>
              </div>
          </div>
          <div id="field-passwd"
            ref="field-passwd"
            class="sr-custom-input"
            :class="{ error: v$.passwd.$errors.length }">
            <input type="password"
              class="form-control"
              name="passwd"
              v-model="v$.passwd.$model"
              @input="v$.passwd.$touch"
              placeholder="ex: Password123@">
            <i class="fas fa-check"></i>
            <div class="input-errors" v-for="error of v$.passwd.$errors" :key="error.$uid">
              <div v-if="error.$uid === 'password-strongPassword'" class="error-msg">
                Please use at least 1 lower case, 1 uppercase, 1 number and 1 special character.
              </div>
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </div>
        <div class="form-group mt--1 mb-3">
          <label class="auth-labels text-uppercase small pl-1" for="confirmation_passwd">
            Confirm Password*
          </label>
          <div id="field-confirmation_passwd"
            ref="field-confirmation_passwd"
            class="sr-custom-input"
            :class="{ error: v$.confirmation_passwd.$errors.length }">
            <input type="password"
              class="form-control"
              name="confirmation_passwd"
              v-model="v$.confirmation_passwd.$model"
              @input="v$.confirmation_passwd.$touch"
              placeholder="ex: Password123@">
            <i class="fas fa-check"></i>
            <div class="input-errors" v-for="error of v$.confirmation_passwd.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </div>
      </form>
    </template>
  </modal>
</template>

<script>
import Modal from './Modal.vue'
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength, email, sameAs } from '@vuelidate/validators'
import CustomInputVue from './CustomInput.vue'
import eventHub from '../utils/eventHub'

export default {
  name: 'settings',
  props: ['openModal', 'modalName'],
  components: {
    modal: Modal,
    customInput: CustomInputVue
  },
  data () {
    return {
      token: null,
      loggedUser: JSON.parse(localStorage.getItem('userData')),
      first_name: '',
      last_name: '',
      email: '',
      passwd: '',
      confirmation_passwd: '',
      valueChanged: false
    }
  },
  created () {
    const userData = localStorage.getItem('userData')
    this.token = localStorage.getItem('accessToken')
    if (userData) {
      const parsedData = JSON.parse(userData)
      this.loggedUser = parsedData
      this.first_name = parsedData.first_name
      this.last_name = parsedData.last_name
      this.email = parsedData.email
    }
  },
  methods: {
    async onSavePassword () {
      const data = {
          type: 'password',
          pword: this.passwd,
          pword_confirmation: this.confirmation_passwd,
          user_id: this.loggedUser.id,
          _method: 'PUT'
      }
      const config = {
          method: 'POST',
          url: process.env.VUE_APP_API + '/profile/update',
          data: data
      }
      this.axios(config).then((response) => {
        if (response?.status === 200) {
            this.valueChanged = false
            this.$refs['field-passwd'].classList.add('field-saved')
            this.$refs['field-confirmation_passwd'].classList.add('field-saved')
            setTimeout(() => {
                this.$refs['field-passwd'].classList.remove('field-saved')
                this.$refs['field-confirmation_passwd'].classList.remove('field-saved')
            }, 2500)
        }
      }).catch(err => {
        if (err?.response?.data?.message === 'Unauthenticated.') {
          eventHub.$emit(this.modalName, false)
          eventHub.$emit('logout', true)
        }
      })
    },
    async passwordsHasErrors () {
      return new Promise((resolve) => {
        setTimeout(() => {
          var hasErrors = false
          if (this.$refs['field-passwd']) {
            const pErrors = this.$refs['field-passwd'].querySelectorAll('.error-msg')
            if (pErrors && pErrors.length > 0) hasErrors = true
          }
          if (this.$refs['field-confirmation_passwd']) {
            const pcErrors = this.$refs['field-confirmation_passwd'].querySelectorAll('.error-msg')
            if (pcErrors && pcErrors.length > 0) hasErrors = true
          }
          resolve(hasErrors)
        }, 100)
      })
    }
  },
  watch: {
    async passwd (newValue, oldValue) {
      const hasErrors = await this.passwordsHasErrors()
      this.valueChanged = newValue !== oldValue &&
      this.passwd === this.confirmation_passwd && !hasErrors
    },
    async confirmation_passwd (newValue, oldValue) {
      const hasErrors = await this.passwordsHasErrors()
      this.valueChanged = newValue !== oldValue &&
      this.passwd === this.confirmation_passwd && !hasErrors
    },
    openModal () {
      this.valueChanged = false
      if (!this.openModal) {
        this.token = null
        this.first_name = ''
        this.last_name = ''
        this.email = ''
        this.passwd = ''
        this.confirmation_passwd = ''
        this.v$.$reset()
      } else {
        const userData = localStorage.getItem('userData')
        this.token = localStorage.getItem('accessToken')
        if (userData) {
          this.loggedUser = JSON.parse(userData)
          const parsedData = JSON.parse(userData)
          this.first_name = parsedData.first_name
          this.last_name = parsedData.last_name
          this.email = parsedData.email
        }
      }
    }
  },
  validations () {
    return {
      first_name: { required, minLength: minLength(2), maxLength: maxLength(100) },
      last_name: { required, minLength: minLength(2), maxLength: maxLength(100) },
      email: { required, email, maxLength: maxLength(100) },
      passwd: {
        required,
        minLength: minLength(8),
        strongPassword (passwd) {
          return (/[a-z]/.test(passwd) && /[A-Z]/.test(passwd) && /[0-9]/.test(passwd) && /\W|_/.test(passwd))
        }
      },
      confirmation_passwd: { required, sameAsPassword: sameAs(this.passwd) }
    }
  },
  setup: () => ({ v$: useVuelidate({ $scope: 'profile' }) })
}
</script>
