<template>
    <div class="wrapper" id="surefooted-wrapper" :style="`background-color: ${colorsCtrl.full_bg}`">
        <div class="container surefooted-shadow swipe-step-3" id="container-3">
            <div class="events">
                <div class="header" :style="`color: ${colorsCtrl.high_h1_fg}`">
                    <div class="eventtitle">
                        Success!
                    </div>
                    <div class="return-start">
                        <button id="return-start" class="closebtn" :style="`color: ${colorsCtrl.p_fg}`">
                            <i class="far fa-times-circle"></i>
                        </button>
                    </div>
                </div>
                <div class="sub-header" :style="`color: ${colorsCtrl.p_fg}`">
                    Your booking has been submitted to Freerein Riding Holidays.
                </div>
                <div class="info-wrapper" :style="`background-color: ${colorsCtrl.field_bg}`">
                    <div class="row">
                        <div class="success-offer-title" :style="`color: ${colorsCtrl.p_fg}`">
                            The Welsh Prince
                        </div>
                    </div>
                    <div class="row">
                        <div class="sure-flex-1 success-data-wrapper">
                            <label :style="`color: ${colorsCtrl.p_fg_sec}`">HOLIDAY START DATE</label>
                            <div :style="`color: ${colorsCtrl.p_fg}`">01/06/21</div>
                        </div>
                        <div class="sure-flex-1 success-data-wrapper">
                            <label :style="`color: ${colorsCtrl.p_fg_sec}`">HOLIDAY END DATE</label>
                            <div :style="`color: ${colorsCtrl.p_fg}`">05/06/21</div>
                        </div>
                        <div class="sure-flex-2 success-data-wrapper">
                            <label :style="`color: ${colorsCtrl.p_fg_sec}`">GUESTS</label>
                            <div :style="`color: ${colorsCtrl.p_fg}`">1 guest</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="sure-flex-1 success-data-wrapper">
                            <label :style="`color: ${colorsCtrl.p_fg_sec}`">YOUR NAME</label>
                            <div :style="`color: ${colorsCtrl.p_fg}`">Matt Mayes</div>
                        </div>
                        <div class="sure-flex-1 success-data-wrapper">
                            <label :style="`color: ${colorsCtrl.p_fg_sec}`">YOUR PHONE NUMBER</label>
                            <div :style="`color: ${colorsCtrl.p_fg}`">0123456789</div>
                        </div>
                        <div class="sure-flex-2 success-data-wrapper">
                            <label :style="`color: ${colorsCtrl.p_fg_sec}`">YOUR EMAIL</label>
                            <div :style="`color: ${colorsCtrl.p_fg}`">matt@example.co.uk</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="sure-flex-1 success-data-wrapper">
                            <label :style="`color: ${colorsCtrl.p_fg_sec}`">AMOUNT PAID</label>
                            <div :style="`color: ${colorsCtrl.p_fg}`">₤110.00</div>
                        </div>
                        <div class="sure-flex-2 success-data-wrapper">
                            <label :style="`color: ${colorsCtrl.p_fg_sec}`">OPERATOR</label>
                            <div :style="`color: ${colorsCtrl.p_fg}`">Freerein Riding Holidays</div>
                        </div>
                        <div class="sure-flex-1 success-data-wrapper"></div>
                    </div>
                    </div>
                    <div class="questions-wrapper">
                        <div class="row">
                            <div class="questions-label" :style="`color: ${colorsCtrl.p_fg_sec}`">
                                Any questions? Drop us an email:
                            </div>
                            <div class="questions-email" :style="`color: ${colorsCtrl.p_fg}`">
                                bookings@free-rein.co.uk
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    name: 'reservation-result-preview',
    props: ['colorsCtrl']
}
</script>

<style lang="scss" scoped>
.container {
    display: block;
    padding-top: 15px;
    padding-bottom: 15px;

    // .far {-webkit-text-stroke: 1px white;}
}
.surefooted-shadow .events {
    border-radius: 10px;
    box-shadow: 0px 3px 10px #d5d7da;
    padding: 20px 30px;
    min-height: 400px;

    .sub-header {
        font-weight: 400;
        font-size: 1.3rem;
        color: #6b6b6b;
    }
    .info-wrapper {
        margin-top: 30px;
        min-height: 100px;
        border-radius: 10px;
        background-color: #f8fafc;
        padding: 15px 20px;

        .row {
            margin-left: 0;
            margin-right: 0;
            display: inline-flex;
            width: 100%;
            margin-bottom: 20px;

            .success-offer-title {
                font-size: 1.4rem;
                font-weight: 600;
            }
            .sure-flex-1 {
                flex: 1;
            }
            .sure-flex-2 {
                flex: 1.5;
            }
            .success-data-wrapper label {
                font-size: 0.83rem;
                color: #a7adc1;
            }
            .success-data-wrapper div {
                font-size: 1rem;
                font-weight: 600;
                margin-top: 2px;
            }
        }
    }
    .questions-wrapper {
        margin-top: 20px;
        margin-bottom: 8px;

        .row {
            margin-left: 0;
            margin-right: 0;

            div {
                font-size: 1rem;
                color: #6b6b6b;
            }
            div.questions-email {
                font-weight: bold;
                margin-left: 5px;
                color: #333;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}
.header {
    width: 100%;
    min-height: 20%;
    display: flex;
    align-items: center;
    padding-bottom: 8px;

    .eventtitle {
        font-size: 2.2em;
        font-weight: 700;
        flex: 1;
    }
    .days {
        flex: 1;
        font-size: 2.2em;
        font-weight: 300;
        padding-left: 10px;
        font-family: "Libre Franklin";
    }
    .return-start {
        height: 40px;
        position: relative;

        .closebtn {
            position: absolute;
            top: -10px;
            right: 0px;
            width: 30px;
            height: 30px;
            font-size: 28px;
            border-width: 0;
            margin-left: 75px;
            background: transparent;
            cursor: pointer;
        }
    }
}
</style>
