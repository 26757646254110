
<template>
    <div class="labels-wrapper">
        <label class="auth-labels text-uppercase small pl-1" :for="name">{{ label }}</label>
        <div class="save-wrapper" v-if="saveBtn && valueChanged">
            <i class="far fa-save" @click="onSave"></i>
        </div>
    </div>
    <div class="sr-custom-input"
        :id="fieldId"
        :class="{ error: errors.length }">
        <input :type="type"
            @blur="onBlur"
            @focus="onFocus"
            class="form-control"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
            :placeholder="placeholder">
        <i class="fas fa-check"></i>
        <div class="input-errors" v-for="error of errors" :key="error.$uid">
            <div :data-error="error.$property" class="error-msg">{{ error.$message }}</div>
        </div>
    </div>
</template>

<script>
import eventHub from '../utils/eventHub'

export default {
    name: 'customInput',
    props: ['modelValue', 'type', 'name', 'label', 'placeholder', 'errors', 'saveBtn', 'validator', 'modalName'],
    emits: ['update:modelValue'],
    data () {
        return {
            token: null,
            loggedUser: JSON.parse(localStorage.getItem('userData')),
            valueChanged: false,
            fieldId: `field-${this.name}`
        }
    },
    created () {
        this.token = localStorage.getItem('accessToken')
        this.loggedUser = JSON.parse(localStorage.getItem('userData'))
    },
    methods: {
        async onSave () {
            const data = {
                type: this.name,
                [this.name]: this.modelValue,
                user_id: this.loggedUser.id,
                _method: 'PUT'
            }
            const config = {
                method: 'POST',
                url: process.env.VUE_APP_API + '/profile/update',
                data: data
            }
            this.axios(config).then((response) => {
               if (response?.status === 200) {
                   const userData = JSON.parse(localStorage.getItem('userData'))
                   userData[this.name === 'company_name' ? 'name' : this.name] = this.modelValue
                   localStorage.setItem('userData', JSON.stringify(userData))
                   this.valueChanged = false
                   const fieldElem = document.getElementById(this.fieldId)
                   if (fieldElem) {
                       fieldElem.classList.add('field-saved')
                       setTimeout(() => {
                           fieldElem.classList.remove('field-saved')
                       }, 2500)
                   }
               }
            }).catch((err) => {
                this.valueChanged = false
                if (err?.response?.data?.message === 'Unauthenticated.') {
                    eventHub.$emit(this.modalName, false)
                    eventHub.$emit('logout', true)
                }
            })
        }
    },
    watch: {
        async modelValue (newValue, oldValue) {
            setTimeout(() => {
                const elemContainer = document.getElementById(this.fieldId)
                if (elemContainer) {
                    const fErrors = elemContainer.querySelectorAll('.error-msg')
                    if (fErrors && fErrors.length > 0) {
                        this.valueChanged = false
                        return
                    }
                }
                this.valueChanged = newValue !== oldValue
            }, 100)
        }
    },
    unmounted () {
        this.token = null
        this.loggedUser = null
    }
}
</script>
