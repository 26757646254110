<template>
    <div>
        <div v-if="type === 'options'">
            <label class="btn btn-outline-light w-100 mb-2" :class="{ 'selected': c.id == value }"
                v-for="c in companyCalendars" :for="`calendar_${c.id}`" :key="c.id">
                <input class="d-none" type="radio" name="calendar" :id="`calendar_${c.id}`" :value="c.id" v-model="value" />
                <span class="d-inline-block">{{ c.name }}</span>
            </label>
        </div>
        <div v-else-if="type === 'select'">
            <select class="form-control w-100 mb-2" v-model="value">
                <option v-for="c in companyCalendars" :key="c.id" name="calendar" :id="`calendar_${c.id}`" :value="c.id">
                    {{ c.name }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    name: 'calendarize-holidays-modal',
    props: {
        modelValue: Number,
        calendars: {
            type: Array,
            default: () => []
        },
        type: {
            default: 'select'
        }
    },
    emits: ['update:modelValue'],
    data () {
        return {
            companyCalendars: []
        }
    },
    computed: {
        value: {
            get: function () {
                return this.modelValue
            },
            set: function (value) {
                this.$emit('update:modelValue', value)
            }
        }
    },
    methods: {
        getCompanyCalendars () {
            const config = {
                method: 'GET',
                url: process.env.VUE_APP_API + `/company/${this.companyId}/calendar`
            }
            this.axios(config)
                .then((response) => {
                    if (response?.status === 200) {
                        this.companyCalendars = response.data
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    mounted () {
        const userData = localStorage.getItem('userData')
        if (userData) {
            const parsedData = JSON.parse(userData)
            this.loggedUser = parsedData
            this.companyId = parsedData.company_id

            if (this.calendars.length === 0) {
                this.getCompanyCalendars()
            } else {
                this.companyCalendars = this.calendars
            }
        }
    },
    watch: {
        calendars (entries) {
            this.companyCalendars = entries
        }
    }
}
</script>
<style lang="scss" scoped>
.btn {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.3);
}

.btn.selected {
    background-color: $primary !important;
    border-color: $primary;
    color: $light;
}

.btn:hover:not(.selected) {
    background-color: #e0e1ed;
}

.instance-errors {
    width: 100%;
    font-size: 10px;
    color: #EF6565;
    padding: 5px 8px 0;
    display: none;
}

.custom-input {
    height: 14px;
    width: 14px;
}

label {
    color: $secondary-gray;
    font-size: 18px !important;
    padding-top: 12px !important;
}
</style>
