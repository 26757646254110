<template>
  <modal id="sr-design-modal" :show="openModal" :modalName="modalName">
      <template v-slot:header>
        <h2>Color Scheme Design</h2>
      </template>
      <template v-slot:body>
          <div class="picker-wrapper">
              <div class="color-pickers">
                  <h5>COLORS</h5>
                  <fixed-calendar-pickers v-if="activeTab === 'tab1'" :colorsCtrl="colorCtrl"></fixed-calendar-pickers>
                  <open-calendar-pickers v-else-if="activeTab === 'tab2'" :colorsCtrl="colorCtrl"></open-calendar-pickers>
                  <info-payment-pickers v-else-if="activeTab === 'tab3'" :colorsCtrl="colorCtrl"></info-payment-pickers>
                  <reservation-result-pickers v-else-if="activeTab === 'tab4'" :colorsCtrl="colorCtrl"></reservation-result-pickers>
              </div>
              <div class="preview">
                  <h5>PREVIEW</h5>
                  <div class="preview-wrapper">
                      <ul class="nav nav-tabs">
                        <li :class="activeTab == 'tab1' ? 'active' : ''" v-on:click="onTabClick('tab1')">
                            <div data-toggle="tab">Fixed Calendar</div>
                        </li>
                        <li :class="activeTab == 'tab2' ? 'active' : ''" v-on:click="onTabClick('tab2')">
                            <div data-toggle="tab">Open Calendar</div>
                        </li>
                        <li :class="activeTab == 'tab3' ? 'active' : ''" v-on:click="onTabClick('tab3')">
                            <div data-toggle="tab">Info & Payment</div>
                        </li>
                        <li :class="activeTab == 'tab4' ? 'active' : ''" v-on:click="onTabClick('tab4')">
                            <div data-toggle="tab">Reservation Result</div>
                        </li>
                      </ul>
                      <div class="tab-content">
                          <fixed-calendar-preview v-if="activeTab === 'tab1'" :colorsCtrl="colorCtrl"></fixed-calendar-preview>
                          <open-calendar-preview v-if="activeTab === 'tab2'" :colorsCtrl="colorCtrl"></open-calendar-preview>
                          <info-payment-preview v-if="activeTab === 'tab3'" :colorsCtrl="colorCtrl"></info-payment-preview>
                          <reservation-result-preview v-if="activeTab === 'tab4'" :colorsCtrl="colorCtrl"></reservation-result-preview>
                      </div>
                  </div>
              </div>
          </div>
      </template>
      <template v-slot:footer>
          <div class="modal-footer">
              <button class="btn btn-cancel" @click="onClose">Cancel</button>
              <button class="btn btn-primary" @click="onSaveDesign">Save</button>
          </div>
      </template>
  </modal>
</template>

<script>
import defaultColors from '../assets/data/default-colors.json'
import eventHub from '../utils/eventHub'
import ModalVue from './Modal.vue'
import FixedCalendarVue from './pickers/FixedCalendar.vue'
import FixedCalendarPreview from './previews/FixedCalendar.vue'
import InfoPaymentVue from './pickers/InfoPayment.vue'
import InfoPaymentPreview from './previews/InfoPayment.vue'
import OpenCalendarVue from './pickers/OpenCalendar.vue'
import OpenCalendarPreview from './previews/OpenCalendar.vue'
import ReservationResultVue from './pickers/ReservationResult.vue'
import ReservationResultPreview from './previews/ReservationResult.vue'
import Utils from '../utils/Utils'

export default {
    name: 'edit-design',
    props: ['openModal', 'modalName'],
    components: {
        modal: ModalVue,
        'fixed-calendar-pickers': FixedCalendarVue,
        'open-calendar-pickers': OpenCalendarVue,
        'info-payment-pickers': InfoPaymentVue,
        'reservation-result-pickers': ReservationResultVue,
        'fixed-calendar-preview': FixedCalendarPreview,
        'info-payment-preview': InfoPaymentPreview,
        'reservation-result-preview': ReservationResultPreview,
        'open-calendar-preview': OpenCalendarPreview
    },
    data () {
        return {
            activeTab: 'tab1',
            token: null,
            savedColors: null,
            colorCtrl: { ...defaultColors.default }
        }
    },
    mounted () {
        eventHub.$on('login', (e) => {
            if (e.access_token) {
                this.token = e.access_token
                this.getSavedColors().then(respColors => {
                    if (respColors?.status === 200) {
                        try {
                            const parseData = JSON.parse(respColors.data.data)
                            this.savedColors = { ...parseData }
                        } catch (error) {
                            this.savedColors = { ...defaultColors.default }
                        }
                    }
                }).catch(error => {
                    if (error?.response?.status === 404) {
                        this.colorCtrl = { ...defaultColors.default }
                    } else {
                        Utils.$handleHttpErrors(error)
                    }
                })
            }
        })
    },
    created () {
        this.token = localStorage.getItem('accessToken')
        eventHub.$on('picked-color', (data) => {
            if (data && data?.prop && data?.color) {
                const { prop, color } = data
                this.colorCtrl[prop] = color
            }
        })

        if (this.token) {
            this.getSavedColors().then(respColors => {
                if (respColors?.status === 200) {
                    const parseData = JSON.parse(respColors.data.data)
                    this.savedColors = { ...parseData }
                }
            }).catch(error => {
                if (error?.response?.status === 404) {
                    this.colorCtrl = { ...defaultColors.default }
                }
            })
        }
    },
    updated () {
        this.token = localStorage.getItem('accessToken')
    },
    methods: {
        onOpenPicker (event) {
            const that = this
            Object.keys(this.btnCtrl).forEach(key => {
                that.btnCtrl[key] = key === `${event.target.id}Show` ? !that.btnCtrl[key] : false
            })
        },
        onTabClick (tab) {
            this.activeTab = tab
        },
        onClose () {
            eventHub.$emit('close-design', {})
        },
        onSaveDesign () {
            const data = new FormData()
            data.append('config', JSON.stringify(this.colorCtrl))
            const config = {
                method: 'post',
                url: process.env.VUE_APP_API + '/holiday/design',
                data: data
            }
            this.axios(config).then(respData => {
                if (respData?.status === 200) {
                    this.getSavedColors().then(respColors => {
                        if (respColors?.status === 200) {
                            const parseData = respColors?.data?.data ? JSON.parse(respColors.data.data) : {}
                            this.savedColors = { ...parseData }
                        } else {
                            this.colorCtrl = { ...defaultColors.default }
                        }
                        this.onClose()
                    }).catch(error => {
                        if (error?.response?.status === 404) {
                            this.colorCtrl = { ...defaultColors.default }
                        } else {
                            Utils.$handleHttpErrors(error)
                        }
                        this.onClose()
                    })
                }
            }).catch(Utils.$handleHttpErrors)
        },
        getSavedColors () {
            return this.axios.get(process.env.VUE_APP_API + '/holiday/design')
        }
    },
    watch: {
        openModal (newValue, oldValue) {
            if (newValue) {
                this.token = localStorage.getItem('accessToken')
                if (this.savedColors) {
                    this.colorCtrl = { ...this.savedColors }
                }
            } else {
                this.activeTab = 'tab1'
            }
        }
    }
}
</script>

<style lang="scss">
    #sr-design-modal {
        top: 5vh;

        .modal__dialog {
            width: 70vw;
            padding: 30px 30px 5px;
        }
        .modal__header {

            h2 {
                width: 100%;
                text-align: center;
            }
        }
        .modal__body {
            margin-left: 10px;
            margin-right: 10px;
        }
        .modal__footer {
            position: relative;
            top: -5px;
        }
        .modal__footer .modal-footer {
            border: none;

            .btn {
                padding: 0.4rem 2rem;
            }
            .btn-cancel {
                background-color: #ececec;
                margin-right: 10px;
            }
        }

        .picker-wrapper {
            display: flex;
            flex-direction: row;

            .color-pickers {
                display: flex;
                flex: 1;
                flex-direction: column;
                position: relative;

                .input-group-append {
                    position: relative;
                }
                .input-group.mb-3 {
                    margin-bottom: 6px !important;
                    position: static;
                }
                .cp-input-title {
                    margin-bottom: 2px;
                }
                .btn-outline-secondary {
                  border-top-right-radius: 0.2rem;
                  border-bottom-right-radius: 0.2rem;
                  border: 1px solid #ced4da;
                }
            }
            .preview {
                display: flex;
                flex: 5;
                flex-direction: column;
                margin-left: 40px;

                .preview-wrapper {
                    position: relative;
                    top: -15px;

                    .nav {
                      justify-content: flex-end;
                    }
                    .nav-tabs {
                        border: none;
                    }
                    .nav-tabs > li {
                        // margin-bottom: -1px;
                        cursor: pointer;
                    }
                    .nav-tabs > li > div {
                        position: relative;
                        display: block;
                        padding: 6px 15px 7px;
                        top: 1px;
                    }

                    .nav-tabs > li.active > div {
                        color: #555;
                        cursor: default;
                        background-color: #fff;
                        border: 1px solid #ddd;
                        border-bottom-color: transparent;
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                    }

                    .tab-content {
                        border: 1px solid #dddddd;
                        height: 45vh;
                        overflow-y: auto;
                        overflow-x: hidden;
                        border-radius: 5px;
                    }
                    .tab-content::-webkit-scrollbar-thumb {
                        background: #9a9a9a;
                        border-radius: 5px;
                    }
                }
            }
        }
    }
</style>
