<template>
  <div class="main">
    <div class="wrapper">
      <h1>This is about to be a page one day...</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'template',
  props: [],
  components: {},
  data () {
    return {

    }
  },
  methods: {

  },
  created () {

  },
  mounted () {

  },
  destroy () {

  }
}
</script>
