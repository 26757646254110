<template>
    <div class="wrapper" id="surefooted-wrapper" :style="`background-color: ${colorsCtrl.full_bg}`">
        <div class="container swipe-step-2" id="container-2">
            <div class="header" :style="`color: ${colorsCtrl.high_h1_fg}`">
                <div class="eventtitle">
                    The Welsh Prince
                </div>
                <div class="days">
                    4 Days
                </div>
            </div>
            <div class="events">
                <div class="eventsheader">
                    <p :style="`color: ${colorsCtrl.p_fg_sec}`">START DATE</p>
                    <p :style="`color: ${colorsCtrl.p_fg_sec}`">AVAILABILITY</p>
                    <p :style="`color: ${colorsCtrl.p_fg_sec}`">PRICE</p>
                </div>
                <div class="eventitems">
                    <div class="item" :style="`background-color: ${colorsCtrl.item_bg}`">
                        <div class="item-wrapper extra-padding-top">
                            <div class="date" :style="`color: ${colorsCtrl.p_fg}; fill: ${colorsCtrl.p_fg_sec}`">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.945 18.945">
                                    <g id="calendar_1_" opacity="0.4">
                                        <path id="Caminho_2" data-name="Caminho 2" d="M17.367,1.776H14.8V.592a.592.592,0,0,0-1.184,0V1.776H10.065V.592a.592.592,0,0,0-1.184,0V1.776H5.328V.592a.592.592,0,0,0-1.184,0V1.776H1.579A1.578,1.578,0,0,0,0,3.355V17.366a1.579,1.579,0,0,0,1.579,1.579H17.367a1.578,1.578,0,0,0,1.578-1.579V3.355A1.578,1.578,0,0,0,17.367,1.776Zm.394,15.59a.4.4,0,0,1-.394.395H1.579a.4.4,0,0,1-.395-.395V3.355a.4.4,0,0,1,.395-.394H4.144V4.144a.592.592,0,0,0,1.184,0V2.96H8.881V4.144a.592.592,0,0,0,1.184,0V2.96h3.552V4.144a.592.592,0,0,0,1.184,0V2.96h2.566a.4.4,0,0,1,.394.394Z"/>
                                        <rect id="Retângulo_87" data-name="Retângulo 87" width="3" height="2" transform="translate(4 7)" />
                                        <rect id="Retângulo_88" data-name="Retângulo 88" width="3" height="2" transform="translate(4 10)" />
                                        <rect id="Retângulo_89" data-name="Retângulo 89" width="3" height="2" transform="translate(4 13)" />
                                        <rect id="Retângulo_90" data-name="Retângulo 90" width="3" height="2" transform="translate(8 13)" />
                                        <rect id="Retângulo_91" data-name="Retângulo 91" width="3" height="2" transform="translate(8 10)" />
                                        <rect id="Retângulo_92" data-name="Retângulo 92" width="3" height="2" transform="translate(8 7)" />
                                        <rect id="Retângulo_93" data-name="Retângulo 93" width="3" height="2" transform="translate(12 13)" />
                                        <rect id="Retângulo_94" data-name="Retângulo 94" width="3" height="2" transform="translate(12 10)" />
                                        <rect id="Retângulo_95" data-name="Retângulo 95" width="3" height="2" transform="translate(12 7)" />
                                    </g>
                                </svg>
                                <div class="weekday">Sat</div>
                                <div class="day">15th</div>
                                <div class="month">May</div>
                                <div class="year" :style="`color: ${colorsCtrl.p_fg_sec}`">2021</div>
                            </div>
                            <div class="separator" :style="`border-left: 1px solid ${colorsCtrl.p_fg_sec}`"></div>
                            <div class="availability" :style="`color: ${colorsCtrl.p_fg}; fill: ${colorsCtrl.p_fg_sec}`">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.227 17">
                                    <path id="iconfinder_00-ELASTOFONT-STORE-READY_user_2703063" d="M12.353,10.535a5.158,5.158,0,1,0-5.388,0c-3.185.713-5.419,2.662-5.419,5.021,0,1.93,5.1,2.445,8.113,2.445s8.113-.515,8.113-2.445C17.773,13.2,15.538,11.248,12.353,10.535ZM5.564,6.151a4.1,4.1,0,1,1,4.1,4.088A4.1,4.1,0,0,1,5.564,6.151Zm4.1,10.787c-4.331,0-6.924-.914-7.051-1.386,0-2.344,3.165-4.25,7.051-4.25s7.047,1.906,7.05,4.249C16.587,16.023,13.994,16.937,9.659,16.937Z" transform="translate(-1.546 -1)"/>
                                </svg>
                                <div class="place">4</div>
                                <div class="availtext" :style="`color: ${colorsCtrl.p_fg_sec}`">PLACES AVAILABLE</div>
                            </div>
                            <div class="separator" :style="`border-left: 1px solid ${colorsCtrl.p_fg_sec}`"></div>
                            <div class="price" :style="`color: ${colorsCtrl.p_fg}`">
                                <div class="currency">₤</div>
                                <div class="value">650</div>
                                <div class="per-person" :style="`color: ${colorsCtrl.p_fg_sec}`">PER PERSON</div>
                                <div>
                                    <button class="closebtn" :style="`color: ${colorsCtrl.p_fg}`">
                                        <i class="far fa-times-circle"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="expanded">
                            <hr :style="`border-top: 1px solid ${colorsCtrl.p_fg_sec}`">
                            <form id="sure-offer-form" index="0" page="0">
                                <div class="row">
                                    <label for="" style="width: 100%" :style="`color: ${colorsCtrl.p_fg}`">
                                        Please enter your details
                                    </label>
                                    <div class="col" :style="`background-color: ${colorsCtrl.field_bg}`">
                                        <label class="fields-title" for="guests" :style="`color: ${colorsCtrl.p_fg}`">GUESTS</label>
                                        <select id="guests" class="fields" name="guests" :style="`color: ${colorsCtrl.p_fg_sec}`" disabled>
                                            <option value="1">1 guest</option>
                                            <option value="2">2 guests</option>
                                            <option value="3">3 guests</option>
                                            <option value="4">4 guests</option>
                                            <option value="5">5 guests</option>
                                            <option value="6">6 guests</option>
                                            <option value="7">7 guests</option>
                                        </select>
                                    </div>
                                    <div class="col col-separator" :style="`background-color: ${colorsCtrl.field_bg}`">
                                        <label class="fields-title" for="name" :style="`color: ${colorsCtrl.p_fg}`">YOUR NAME</label>
                                        <input id="name" type="text" class="fields" :style="`color: ${colorsCtrl.p_fg_sec}`" value="Matt Mayes" disabled>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col" :style="`background-color: ${colorsCtrl.field_bg}`">
                                        <label class="fields-title" for="phone" :style="`color: ${colorsCtrl.p_fg}`">YOUR PHONE NUMBER</label>
                                        <input type="text" class="fields" id="phone" :style="`color: ${colorsCtrl.p_fg_sec}`" value="0123455789" disabled>
                                    </div>
                                    <div class="col col-separator" :style="`background-color: ${colorsCtrl.field_bg}`">
                                        <label class="fields-title" for="email" :style="`color: ${colorsCtrl.p_fg}`">YOUR EMAIL</label>
                                        <input id="email" type="text" class="fields" :style="`color: ${colorsCtrl.p_fg_sec}`" value="matt@example.co.uk" disabled>
                                    </div>
                                </div>

                                <div class="row info" :style="`background-color: ${colorsCtrl.tdue_bg}`">
                                    <div class="col no-border" style="width: 60%">
                                        <div class="notice" :style="`color: ${colorsCtrl.tdue_fg}`">
                                            18% per guest non-refundable deposit
                                        </div>
                                    </div>
                                    <div class="col col-separator no-border" style="width: 35%">
                                        <div :style="`color: ${colorsCtrl.tdue_fg}`">TOTAL DUE NOW</div>
                                        <div class="currency" :style="`color: ${colorsCtrl.p_fg}`">₤</div>
                                        <div id="due-now" index="0" page="0" class="total" :style="`color: ${colorsCtrl.p_fg}`">117.00</div>
                                    </div>
                                </div>

                                <div class="row" :style="`background-color: ${colorsCtrl.field_bg}`">
                                    <div id="card-data-container" class="col full-width">
                                        <label class="fields-title" for="card" :style="`color: ${colorsCtrl.p_fg}`">PAYMENT CARD</label>
                                        <div class="card-data">
                                            <div class="card-icon" :style="`color: ${colorsCtrl.p_fg}`">
                                                <i class="far fa-credit-card"></i>
                                            </div>
                                            <input id="card" type="text" maxlength="19" :style="`color: ${colorsCtrl.p_fg_sec}`" placeholder="Card Number" value="4556 7541 4456 1123" class="fields" disabled>
                                            <div class="card-date">
                                                <input id="card-month" type="text" maxlength="2" :style="`color: ${colorsCtrl.p_fg_sec}`" placeholder="MM" value="10" class="fields" disabled>
                                                <span class="card-separator" :style="`color: ${colorsCtrl.p_fg_sec}`">/</span>
                                                <input id="card-year" type="text" maxlength="2" :style="`color: ${colorsCtrl.p_fg_sec}`" placeholder="YY" value="25" class="fields" disabled>
                                            </div>
                                            <input id="card-csv" type="text" maxlength="3" :style="`color: ${colorsCtrl.p_fg_sec}`" class="fields" value="841" placeholder="CSV" disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" :style="`background-color: ${colorsCtrl.field_bg}`">
                                    <div class="col full-width">
                                        <label class="fields-title" for="card-name" :style="`color: ${colorsCtrl.p_fg}`">CARDHOLDER NAME</label>
                                        <input type="text" id="card-name" value="Matt Mayes" :style="`color: ${colorsCtrl.p_fg_sec}`" class="fieldsfull" disabled>
                                    </div>
                                </div>
                                <div class="submit">
                                    <div class="row">
                                        <div class="col2 custom-col-6 no-border">
                                            <label class="custom-ck">
                                                <div :style="`color: ${colorsCtrl.p_fg}`">I HAVE READ AND AGREE TO THE
                                                    <a target="_blank" :href="this.url_condition" :style="`color: ${colorsCtrl.p_fg}`">BOOKING CONDITIONS</a>
                                                </div>
                                                <input type="checkbox" id="conditions" name="scales" checked="" disabled>
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <div class="col2 custom-col-4 align-right">
                                            <button @mouseout="onButtonOut" @mouseover="onButtonHover" type="button" id="book-now" name="book-now" :style="`color: ${colorsCtrl.high_but_fg};background-color: ${colorsCtrl.high_but_bg}; border: 2px solid ${colorsCtrl.high_but_fg}`">
                                                Book Now →
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'info-payment-preview',
    props: ['colorsCtrl'],
    data () {
    var parsedData = null
    const userData = localStorage.getItem('userData')
    if (userData) {
      parsedData = JSON.parse(userData)
    }
    return {
      token: localStorage.getItem('accessToken'),
      loggedUser: parsedData,
      company_name: parsedData ? parsedData.name : '',
      country: parsedData ? parsedData.country : '',
      url_condition: parsedData ? parsedData.url_condition : ''
    }
  },
    methods: {
        onButtonHover (event) {
            event.target.style.color = this.colorsCtrl.high_but_fg_hover
            event.target.style.backgroundColor = this.colorsCtrl.high_but_bg_hover
        },
        onButtonOut (event) {
            event.target.style.color = this.colorsCtrl.high_but_fg
            event.target.style.backgroundColor = this.colorsCtrl.high_but_bg
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    height: auto;
    width: 100%;
    display: inline-flex;
}
.container {
    flex: 1;
    display: block;
    max-width: 800px;
    height: 100%;
    flex-direction: column;
    font-family: "Circular-Loom", sans-serif;
    margin: 0 auto;
    padding: 20px;
    position: relative;

    // .far {-webkit-text-stroke: 1px white;}
}
.header {
    width: 100%;
    min-height: 20%;
    display: flex;
    align-items: flex-end;
    padding-bottom: 2%;

    .eventtitle {
        font-size: 2.2em;
        font-weight: 700;
    }
    .days {
        flex: 1;
        font-size: 2.2em;
        font-weight: 300;
        padding-left: 10px;
        font-family: "Libre Franklin";
    }
}
.events {
    min-height: 290px;

    .eventsheader {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 5px;
        font-weight: 100;
    }
    .eventsheader p {
        display: inline-block;
        width: 30%;
        padding-left: 20px;
        font-size: 0.8rem;
        // color: #8c8c8c;
    }
    .eventitems .item {
        padding: 20px 0 2px;
        border-radius: 10px;
        box-shadow: 0px 3px 10px #d5d7da;
        margin-bottom: 12px;
        -webkit-transition: 0.5s;
        -moz-transition: 0.5s;
        -ms-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;
    }
    .eventitems .item .item-wrapper {
        display: flex;
        cursor: pointer;
        padding: 0 20px;

        .date {
            display: inline-flex;
            align-items: center;
            width: 30%;

            svg {
                vertical-align: middle;
                width: 20px;
                margin-right: 5px;
                transform: translateY(-2px);
            }

            div {
                display: inline-block;
                font-weight: 700;
                margin: 0 2px;
                font-size: 1.2rem;
            }
            .year {
                font-weight: 300;
                color: #717171;
            }
        }

        .separator {
            border-left: 2px solid #f6f6f6;
        }

        .availability {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 30%;

            svg {
                width: 18px;
                display: inline-block;
                margin-right: 2px;
            }
            .place {
                font-size: 1.2rem;
                margin-right: 3px;
                font-weight: 700;
            }
            .availtext {
                font-weight: 200;
            }
            div {
                display: inline-block;
                font-size: 0.78rem;
                font-weight: 200;
                margin: 0 2px;
                vertical-align: middle;
            }
        }

        .price {
            display: inline-flex;
            align-items: center;
            width: 40%;
            justify-content: flex-end;

            .currency {
                display: inline-block;
                font-size: 1.2rem;
                font-weight: 700;
            }
            .value {
                display: inline-block;
                margin-right: 4px;
                font-size: 1.2rem;
                font-weight: 700;
            }
            div.per-person {
                margin-top: 2px;
                color: #8c8c8c;
            }
            div {
                display: inline-block;
                font-size: 0.78rem;
                font-weight: 200;
                vertical-align: middle;
            }
            .select {
                display: inline-block;
                padding: 8px 13px;
                border-radius: 10px;
                border: 2px solid #525252;
                background-color: #ffff;
                letter-spacing: 1px;
                font-size: 1rem;
                font-weight: 600;
                margin-left: 38px;
                cursor: pointer;
                color: #525252;
            }

            .closebtn {
                width: 30px;
                height: 30px;
                border-width: 0;
                margin-left: 75px;
                background: transparent;
                cursor: pointer;
                font-size: 28px;
            }
        }
    }
    .eventitems .item {
        input:disabled {
            background-color: transparent;
        }

        .expanded form {
            padding: 0 20px;

            .row {
                font-size: 0;
                margin-bottom: 25px;
                margin-left: 0;
                margin-right: 0;

                .col {
                    display: inline-block;
                    width: 48%;
                    vertical-align: top;
                    height: 70px;
                    border-radius: 5px;
                    border: 1px solid #cccccc;
                    padding: 10px 15px;
                }
                .col-separator {
                    display: inline-block;
                    margin-left: 4%;
                }
            }
            label {
                font-size: 16px !important;
                font-weight: bold !important;
                display: block;
                text-align: start;
                margin-bottom: 8px;
            }
            label.fields-title {
                font-size: 0.8rem !important;
                color: #454545;
                margin-bottom: 4px !important;
            }
            select.fields {
                font-size: 1.4rem !important;
                border: none;
                color: #858585;
                margin-top: -2px;
                width: 100%;
                background: transparent;
            }
            .fields[type="text"] {
                color: #858585;
                font-size: 1.3rem !important;
                border: none;
                flex: 1;
                margin-top: -2px;
            }
            .info {
                background-color: #f8fafc;
                border-radius: 10px;
                display: block;

                .col {
                    display: inline-flex;
                    align-items: center;
                    height: 60px;

                    .notice {
                        font-size: 1.2rem !important;
                        text-align: start;
                        font-weight: 500;
                        color: #848484;
                    }
                }
                .col.col-separator.no-border {
                    display: inline-flex;
                    font-size: 11.5px;
                    align-items: center;
                    justify-content: flex-end;
                    color: #828282;

                    div:nth-child(1) {
                        margin-top: 2px;
                    }
                }
                div.currency {
                    margin-left: 10px;
                }
                div.currency, div.total {
                            font-weight: 600;
                            font-size: 1.2rem;
                            color: #000;
                        }
                .no-border {
                    border: none !important;
                }
            }
            .col.full-width {
                width: 100% !important;

                [type="text"] {
                    color: #858585;
                    font-size: 1.2rem !important;
                    border: none;
                    // flex: 1 !important;
                    margin-top: -2px;
                }
                .card-data {
                    display: flex;
                    width: 100%;
                }
                .card-data .card-date {
                    display: flex;
                    width: 36%;
                }
                .card-data #card {
                    width: 50%;
                    flex: none;
                }
                .card-data .card-icon {
                    display: flex;
                    align-items: center;
                    margin-right: 10px;
                    font-size: 22px;
                }
                .card-data #card-month {
                    flex: none;
                    width: 82%;
                    text-align: right;
                }
                .card-data .card-date .card-separator {
                    font-size: 20px;
                    color: #858585;
                }
                .card-data #card-year {
                    flex: none;
                    width: 18%;
                    margin-left: 2px;
                }
                .card-data #card-csv {
                    width: 14%;
                    margin-left: 10px;
                    text-align: right;
                }
            }

            .submit .row .col2.custom-col-6.no-border {
                flex: 2;
                display: flex;
                align-items: center;
            }
            .submit .row .col2.align-right {
                text-align: end;
            }
            .custom-col-6 {
                width: 60%;
            }
            .custom-col-4 {
                width: 40%;
            }
            .submit .row .col2 {
                display: inline-block;
                flex: 1;
            }
            .submit .row .col2 #book-now {
                display: inline-flex;
                height: 60px;
                width: 160px;
                position: relative;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                padding: 12px 12px;
                border-radius: 10px;
                border: 2px solid #444446;
                background-color: #ffffff;
                color: #444446;
                letter-spacing: 1px;
                font-size: 1.1rem;
                transition: 0.1s;
                cursor: pointer;
            }
            .submit .row .custom-ck {
                display: block;
                position: relative;
                cursor: pointer;
                font-size: 0.8rem !important;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }
            .submit .row .custom-ck div {
                margin-left: 32px;
                padding-top: 2px;

                a {
                    color: #444446;
                    text-decoration: underline !important;
                }
            }
            .submit .row .custom-ck input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }
            .submit .row div #conditions {
                display: inline-block;
                margin-right: 12px;
            }
            .submit .row .custom-ck input:checked ~ .checkmark {
                background-color: #454545;
                border-radius: 2px;
            }
            .submit .row .custom-ck .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 20px;
                width: 20px;
                background-color: #fff;
                border: 1px solid #858585;
                border-radius: 2px;
            }
            .submit .row .custom-ck input:checked ~ .checkmark:after {
                display: block;
            }
            .submit .row .custom-ck .checkmark:after {
                left: 6px;
                top: 0px;
                width: 7px;
                height: 14px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            .submit .row .custom-ck .checkmark:after {
                content: "";
                position: absolute;
                display: none;
            }
        }

    }

    div.total {
        font-weight: 600;
        font-size: 1.2rem;
        color: #000;
    }
}
</style>
