<template>
  <transition name="fade">
    <div class="modal" :class="{'p-md-4': fullscreen}" v-if="show">
      <div class="modal__backdrop" @click="closeModal"/>

      <div class="modal__dialog" :class="{'w-100 h-auto m-0': fullscreen}" >
        <div class="modal__header">
          <slot name="header"/>
          <span class="modal__close" @click="closeModal">
            <svg xmlns="http://www.w3.org/2000/svg" width="18.828" height="18.828" viewBox="0 0 18.828 18.828">
                <g id="Close_Modal" data-name="Close Modal" transform="translate(1.414 1.414)">
                    <g data-name="Grupo 67" opacity="0.35">
                    <line data-name="Linha 45" x2="16" y2="16" fill="none" stroke="#7e84a3" stroke-linecap="round" stroke-width="2"/>
                    <line data-name="Linha 46" x2="16" y2="16" transform="translate(16) rotate(90)" fill="none" stroke="#7e84a3" stroke-linecap="round" stroke-width="2"/>
                    </g>
                </g>
            </svg>
          </span>
        </div>

        <div class="modal__body">
          <slot name="body"/>
        </div>

        <div class="modal__footer">
          <slot name="footer"/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import eventHub from '../utils/eventHub'

export default {
  name: 'Modal',
  props: {
      show: {
          type: Boolean,
          required: true
      },
      modalName: {
          type: String,
          required: true
      },
      fullscreen: {
          type: Boolean,
          default: false
      }
  },
  data () {
      return {
        //   show: false
      }
  },
  methods: {
      closeModal () {
        eventHub.$emit(this.modalName, false)
        document.querySelector('body').classList.remove('overflow-hidden')
      },
      openModal () {
        document.querySelector('body').classList.add('overflow-hidden')
      }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  &__backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  &__dialog {
    background-color: #ffffff;
    position: relative;
    width: 600px;
    margin: 50px auto;
    padding: 30px 25px 50px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    z-index: 2;
    @media screen and (max-width: 992px) {
      width: 90%;
    }
  }
  &__close {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-top: 5px;
  }
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    z-index: 10;
  }
  &__body {
    overflow: visible;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 20px;
  }
  &__footer {
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
