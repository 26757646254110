<template>
  <div class="pickers-container">
    <label class="cp-input-title" for="high_h1_fg">Title</label>
        <div class="input-group input-group-sm mb-3">
            <input type="text" class="form-control" :placeholder="colorsCtrl.high_h1_fg">
            <div class="input-group-append">
            <button id="high_h1_fg" @click="onOpenPicker" class="btn btn-outline-secondary" type="button" :style="`background-color: ${colorsCtrl.high_h1_fg}`">
                <i class="fas fa-brush"></i>
            </button>
            </div>
            <color-picker-popover :show="btnCtrl.high_h1_fg" name="high_h1_fg" :color="colorsCtrl.high_h1_fg"></color-picker-popover>
        </div>
        <label class="cp-input-title" for="full_bg">Background</label>
        <div class="input-group input-group-sm mb-3">
            <input type="text" class="form-control" :placeholder="colorsCtrl.full_bg">
            <div class="input-group-append">
            <button id="full_bg" @click="onOpenPicker" class="btn btn-outline-secondary" type="button" :style="`background-color: ${colorsCtrl.full_bg}`">
                <i class="fas fa-brush"></i>
            </button>
            </div>
            <color-picker-popover :show="btnCtrl.full_bg" name="full_bg" :color="colorsCtrl.full_bg"></color-picker-popover>
        </div>
        <label class="cp-input-title" for="item_bg">Item Background</label>
        <div class="input-group input-group-sm mb-3">
            <input type="text" class="form-control" :placeholder="colorsCtrl.item_bg">
            <div class="input-group-append">
            <button id="item_bg" @click="onOpenPicker" class="btn btn-outline-secondary" type="button" :style="`background-color: ${colorsCtrl.item_bg}`">
                <i class="fas fa-brush"></i>
            </button>
            </div>
            <color-picker-popover :show="btnCtrl.item_bg" name="item_bg" :color="colorsCtrl.item_bg"></color-picker-popover>
        </div>
        <label class="cp-input-title" for="p_fg">Text Primary</label>
        <div class="input-group input-group-sm mb-3">
            <input type="text" class="form-control" :placeholder="colorsCtrl.p_fg">
            <div class="input-group-append">
            <button id="p_fg" @click="onOpenPicker" class="btn btn-outline-secondary" type="button" :style="`background-color: ${colorsCtrl.p_fg}`">
                <i class="fas fa-brush"></i>
            </button>
            </div>
            <color-picker-popover :show="btnCtrl.p_fg" name="p_fg" :color="colorsCtrl.p_fg"></color-picker-popover>
        </div>
        <label class="cp-input-title" for="p_fg_sec">Text Secondary</label>
        <div class="input-group input-group-sm mb-3">
            <input type="text" class="form-control" :placeholder="colorsCtrl.p_fg_sec">
            <div class="input-group-append">
            <button id="p_fg_sec" @click="onOpenPicker" class="btn btn-outline-secondary" type="button" :style="`background-color: ${colorsCtrl.p_bg_sec}`">
                <i class="fas fa-brush"></i>
            </button>
            </div>
            <color-picker-popover :show="btnCtrl.p_fg_sec" name="p_fg_sec" :color="colorsCtrl.p_fg_sec"></color-picker-popover>
        </div>
        <label class="cp-input-title" for="high_but_fg">Buttons Text</label>
        <div class="input-group input-group-sm mb-3">
            <input type="text" class="form-control" :placeholder="colorsCtrl.high_but_fg">
            <div class="input-group-append">
            <button id="high_but_fg" @click="onOpenPicker" class="btn btn-outline-secondary" type="button" :style="`background-color: ${colorsCtrl.high_but_fg}`">
                <i class="fas fa-brush"></i>
            </button>
            </div>
            <color-picker-popover :show="btnCtrl.high_but_fg" name="high_but_fg" :color="colorsCtrl.high_but_fg"></color-picker-popover>
        </div>
        <label class="cp-input-title" for="high_but_fg_hover">Buttons Text Hover</label>
        <div class="input-group input-group-sm mb-3">
            <input type="text" class="form-control" :placeholder="colorsCtrl.high_but_fg_hover">
            <div class="input-group-append">
            <button id="high_but_fg_hover" @click="onOpenPicker" class="btn btn-outline-secondary" type="button" :style="`background-color: ${colorsCtrl.high_but_fg_hover}`">
                <i class="fas fa-brush"></i>
            </button>
            </div>
            <color-picker-popover :show="btnCtrl.high_but_fg_hover" name="high_but_fg_hover" :color="colorsCtrl.high_but_fg_hover"></color-picker-popover>
        </div>
        <label class="cp-input-title" for="high_but_bg">Buttons Background</label>
        <div class="input-group input-group-sm mb-3">
            <input type="text" class="form-control" :placeholder="colorsCtrl.high_but_bg">
            <div class="input-group-append">
            <button id="high_but_bg" @click="onOpenPicker" class="btn btn-outline-secondary" type="button" :style="`background-color: ${colorsCtrl.high_but_bg}`">
                <i class="fas fa-brush"></i>
            </button>
            </div>
            <color-picker-popover :show="btnCtrl.high_but_bg" name="high_but_bg" :color="colorsCtrl.high_but_bg"></color-picker-popover>
        </div>
        <label class="cp-input-title" for="high_but_bg_hover">Buttons Background Hover</label>
        <div class="input-group input-group-sm mb-3">
            <input type="text" class="form-control" :placeholder="colorsCtrl.high_but_bg_hover">
            <div class="input-group-append">
            <button id="high_but_bg_hover" @click="onOpenPicker" class="btn btn-outline-secondary" type="button" :style="`background-color: ${colorsCtrl.high_but_bg_hover}`">
                <i class="fas fa-brush"></i>
            </button>
            </div>
            <color-picker-popover :show="btnCtrl.high_but_bg_hover" name="high_but_bg_hover" :color="colorsCtrl.high_but_bg_hover"></color-picker-popover>
        </div>
        <label class="cp-input-title" for="high_selected_date_bg">Selected Date</label>
        <div class="input-group input-group-sm mb-3">
            <input type="text" class="form-control" :placeholder="colorsCtrl.high_selected_date_bg">
            <div class="input-group-append">
            <button id="high_selected_date_bg" @click="onOpenPicker" class="btn btn-outline-secondary" type="button" :style="`background-color: ${colorsCtrl.high_selected_date_bg}`">
                <i class="fas fa-brush"></i>
            </button>
            </div>
            <color-picker-popover :show="btnCtrl.high_selected_date_bg" name="high_selected_date_bg" :color="colorsCtrl.high_selected_date_bg"></color-picker-popover>
        </div>
        <label class="cp-input-title" for="high_selected_date_fg">Selected Date Text</label>
        <div class="input-group input-group-sm mb-3">
            <input type="text" class="form-control" :placeholder="colorsCtrl.high_selected_date_fg">
            <div class="input-group-append">
            <button id="high_selected_date_fg" @click="onOpenPicker" class="btn btn-outline-secondary" type="button" :style="`background-color: ${colorsCtrl.high_selected_date_fg}`">
                <i class="fas fa-brush"></i>
            </button>
            </div>
            <color-picker-popover :show="btnCtrl.high_selected_date_fg" name="high_selected_date_fg" :color="colorsCtrl.high_selected_date_fg"></color-picker-popover>
        </div>
    </div>
</template>

<script>
import eventHub from '../../utils/eventHub'
import ColorPickerPopoverVue from '../common/ColorPickerPopover.vue'

export default {
    name: 'fixed-calendar',
    components: {
        'color-picker-popover': ColorPickerPopoverVue
    },
    props: ['colorsCtrl'],
    data () {
        return {
            btnCtrl: {
                high_h1_fg: false,
                full_bg: false,
                item_bg: false,
                p_fg: false,
                p_fg_sec: false,
                high_but_fg: false,
                high_but_fg_hover: false,
                high_but_bg: false,
                high_but_bg_hover: false,
                high_selected_date_bg: false,
                high_selected_date_fg: false
            }
        }
    },
    created () {
        eventHub.$on('close-picker', (name) => {
            this.btnCtrl[name] = false
        })
    },
    methods: {
        onOpenPicker (event) {
            const that = this
            if (event?.target?.parentNode?.parentNode) {
                const pkContainer = document.querySelector('.pickers-container')
                const itemPos = event.target.parentNode.parentNode.getBoundingClientRect()
                const elem = event.target.parentNode.parentNode.querySelector('.popover-wrapper')
                elem.style.top =
                (event.target.parentNode.parentNode.offsetTop - pkContainer.scrollTop) <= 380 ? (itemPos.top - 207) + 'px' : (itemPos.bottom - 609) + 'px'
                // elem.style.left = `${itemPos.left + 50}px`
            }
            Object.keys(this.btnCtrl).forEach(key => {
                that.btnCtrl[key] = key === `${event.target.id}` ? !that.btnCtrl[key] : false
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .pickers-container {
        height: 47vh;
        overflow-x: hidden;
        overflow-y: auto;
        padding-right: 10px;

        .btn-outline-secondary > i {
            pointer-events: none;
            color: #ffffff;
        }
        .fa-brush:before {
            text-shadow: 0 0 2px #000;
        }
    }
    ::-webkit-scrollbar-thumb {
        background: #9a9a9a;
        border-radius: 5px;
    }
</style>
