<template>
  <modal ref="calendar-modal" :show="openModal" :modalName="modalName" fullscreen>
    <template v-slot:header>
      <h1>Open Dates Calendar</h1>
    </template>
    <template v-slot:body>
      <div class="row">
        <div class="col-lg-4">
          <div class="form-group holiday-commons mb-4">
            <label class="float-left text-uppercase pl-1">Select Calendar</label>
            <CalendarSelect v-model="calendarId" type="options" class="text-left" @update:modelValue="setActiveCalendar"
              :calendars="companyCalendars" />
            <div class="float-right my-4" v-if="!(editing || creating)">
              <a class="btn btn-outline-secondary shadow-md" @click="editCalendar"><i class="fa fa-pencil"></i> Edit</a>
              <a class="btn btn-outline-secondary ml-2" @click="createNewCalendar"><i class="fa fa-plus"></i> Add</a>
            </div>
          </div>
        </div>
        <div class="col-lg-8">
          <div v-if="editing || creating">
            <label class="text-uppercase pl-1">Edit your calendar details</label>
            <CalendarEdit v-model="selectedCalendar" @save="onSaveCalendar" @cancel="editing = creating = false" />
          </div>
          <div v-else>
            <label class="text-uppercase pl-1">Click on the calendar days to set open dates</label>
            <VCalendar v-model.range="pickedPeriod" :rows="calendarRows" :columns="calendarColumns" :expanded="expanded"
              :attributes="attributes" timezone="UTC" @daykeydown.escape="abortPeriodSelection" @dayclick="onDayClick" />
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from './Modal.vue'
import { useScreens } from 'vue-screen-utils'
import eventHub from '../utils/eventHub'
import CalendarSelect from '../components/CalendarSelect.vue'
import CalendarEdit from '../components/CalendarEdit.vue'

const { mapCurrent } = useScreens({
  xs: '0px',
  sm: '640px',
  md: '860px',
  lg: '1140px'
})

const todayAttribute = {
  key: 'today',
  highlight: { color: 'gray' },
  dates: [new Date()]
}

const swalTypes = {
  confirmRemovePeriods: {
    icon: 'confirm',
    title: 'The matched periods will be removed. Continue?',
    cancelButton: true,
    okbuttonText: 'Yes',
    okButtonColor: '#59c154'
  },
  confirmSetDate: {
    icon: 'info',
    title: 'Do you really want to set a new open period?',
    cancelButton: true,
    okbuttonText: 'Ok',
    okButtonColor: '#59c154'
  },
  invalidPeriod: {
    icon: 'error',
    title: 'You set an invalid period.',
    cancelButton: false,
    okbuttonText: 'Close',
    okButtonColor: '#59c154'
  },
  invalidUser: {
    icon: 'error',
    title: '',
    cancelButton: false,
    okbuttonText: 'Ok',
    okButtonColor: '#59c154'
  }
}

export default {
  name: 'Calendar',
  props: ['openModal', 'modalName'],
  components: {
    modal: Modal,
    CalendarSelect,
    CalendarEdit
  },
  data () {
    return {
      token: null,
      calendarColumns: mapCurrent({ lg: 3, md: 2, sm: 2 }, 1),
      calendarRows: mapCurrent({ lg: 3, md: 3, sm: 3 }, 4),
      expanded: true,
      attributes: [
        todayAttribute,
        {
          key: 'available',
          highlight: { fillMode: 'solid', color: 'indigo' },
          dates: []
        }
      ],
      companyCalendars: [],
      calendarId: null,
      selectedCalendar: null,
      pickedPeriod: {},
      loggedUser: null,
      creating: false,
      editing: false
    }
  },
  mounted () {
    eventHub.$on('set-open-date', (period) => this.setOpenDates(period))
  },
  unmounted () {
    eventHub.$off('set-open-date')
  },
  methods: {
    async getCompanyCalendars () {
      this.resetCalendar()
      const config = {
        method: 'GET',
        url: process.env.VUE_APP_API + `/company/${this.companyId}/calendar`
      }
      this.axios(config)
        .then((response) => {
          if (response?.status === 200) {
            this.companyCalendars = response.data
            this.calendarId = response.data[0].id
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message === 'Unauthenticated.') {
            eventHub.$emit(this.modalName, false)
            eventHub.$emit('logout', true)
          }
        })
    },
    async getCalendarOpenDates () {
      const config = {
        method: 'GET',
        url: process.env.VUE_APP_API + `/calendar/${this.calendarId}/opendates`
      }
      this.axios(config)
        .then((response) => {
          if (response?.status === 200) {
            this.resetCalendar()
            response.data.forEach((inst) => {
              this.paintPeriod(inst.date_start, inst.date_end)
            })
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message === 'Unauthenticated.') {
            eventHub.$emit(this.modalName, false)
            eventHub.$emit('logout', true)
          }
        })
    },
    async saveOpenDatesPeriod (period) {
      const config = {
        method: 'POST',
        url: process.env.VUE_APP_API + `/calendar/${this.calendarId}/opendates`,

        data: {
          calendar_id: this.calendarId,
          date_start: period.start,
          date_end: period.end
        }
      }

      this.axios(config)
        .then((response) => {
          if (response?.status === 200) {
            const p = response.data.original.instance
            this.paintPeriod(p.date_start, p.date_end)
            this.abortPeriodSelection()
          }
        })
        .catch((err) => {
          if (err.response?.data?.msg) {
            this.showAlert('invalidUser', err.response?.data?.msg)
              .then(async (result) => {
              })
          }
          if (err?.response?.data?.message === 'Unauthenticated.') {
            eventHub.$emit(this.modalName, false)
            eventHub.$emit('logout', true)
          }
        })
    },
    paintPeriod (start, end) {
      this.availablePeriods.dates.push({
        start: new Date(start),
        end: new Date(end)
      })
    },

    clearPeriod (start, end) {
      this.availablePeriods.dates = this.availablePeriods.dates.filter((p) => !(p.start <= start && p.end >= end))
    },

    setOpenDates (period) {
      this.showAlert('confirmSetDate', '')
        .then(async (result) => {
          if (result.isConfirmed) {
            this.saveOpenDatesPeriod(period)
          }
          this.abortPeriodSelection()
        })
    },

    showAlert (type, layer) {
      return this.$swal({
        icon: swalTypes[type].icon,
        title: `${type === 'invalidUser' ? layer : swalTypes[type].title} ${type === 'invalidUser' ? '' : layer}`,
        showCancelButton: swalTypes[type].cancelButton,
        confirmButtonText: swalTypes[type].okbuttonText,
        confirmButtonColor: swalTypes[type].okbuttonColor ? '#59c154' : '#ef6565'
      })
    },

    async onDayClick (day) {
      const isAvailable = this.findMatchedPeriod(day.date) === undefined

      if (!isAvailable) {
        const period = { start: day.date, end: day.date }
        this.removeOpenDatePeriod(period)
        this.abortPeriodSelection()
      }
      await this.getCalendarOpenDates()
    },

    // Cancels the selected period
    abortPeriodSelection () {
      this.$nextTick(() => { this.pickedPeriod = {} })
    },

    // Returns periods that match with selected day.
    findMatchedPeriod (date) {
      return this.availablePeriods.dates.find((d) => d.start <= date && date <= d.end)
    },

    // Returns periods that match with selected day.
    getMatchedPeriods (date) {
      return this.availablePeriods.dates.filter((d) => d.start <= date && date <= d.end)
    },

    // Removes a period from calendar
    removeOpenDatePeriod (period) {
      const config = {
        method: 'DELETE',
        url: process.env.VUE_APP_API + `/calendar/${this.calendarId}/opendates`,
        data: { calendar_id: this.calendarId, date_start: period.start, date_end: period.end }
      }
      this.axios(config).then((response) => {
        if (response?.status === 200) {
          this.clearPeriod(period.start, period.end)
          this.abortPeriodSelection()
        }
      })
    },
    resetCalendar () {
      this.availablePeriods.dates = []
    },
    setActiveCalendar (calendarId) {
      this.selectedCalendar = this.companyCalendars.find(c => c.id === calendarId)
    },
    createNewCalendar () {
      this.creating = true
      this.calendarId = null
      this.selectedCalendar = { name: '', description: '' }
    },
    editCalendar () {
      this.setActiveCalendar(this.calendarId)
      this.editing = true
    },
    onSaveCalendar (data) {
      if (data) {
        this.companyCalendars = data
        this.editing = this.creating = false
      }
    }
  },
  computed: {
    availablePeriods () {
      return this.attributes.find((p) => p.key === 'available')
    },
    pickedPeriodDays () {
      if (this.pickedPeriod) {
        return (
          Math.ceil(
            (this.pickedPeriod.end - this.pickedPeriod.start) /
            (1000 * 3600 * 24)
          ) + 1
        )
      }
      return 0
    }
  },
  watch: {
    openModal (v) {
      if (this.openModal) {
        const userData = localStorage.getItem('userData')
        if (userData) {
          const parsedData = JSON.parse(userData)
          this.loggedUser = parsedData
          this.companyId = parsedData.company_id
          this.getCompanyCalendars()
        }
      }
    },
    pickedPeriod (period) {
      const isEmpty = (x) => Object.keys(x).length > 0

      if (isEmpty(period ?? {})) {
        eventHub.$emit('set-open-date', period)
      }
    },
    calendarId (id) {
      if (id != null) {
        this.getCalendarOpenDates()
      } this.resetCalendar()
    }
  }
}
</script>
