<template>
  <modal ref="profile-modal" :show="openModal" :modalName="modalName">
    <template v-slot:header :close="closeModal">
      <h1>Edit Profile</h1>
    </template>
    <template v-slot:body>
      <div class="form-group mt--1 mb-3">
        <!--           <customInput type="text"
            v-model="v$.company_name.$model"
            :validator="v$"
            :errors="v$.company_name.$errors"
            name="company_name"
            label="Company name*"
            :modalName="modalName"
            placeholder="ex: Facebook Inc."
            saveBtn="true">
          </customInput> -->
        <label class="auth-labels text-uppercase small pl-1">
          Company name*
        </label>
        <div class="sr-custom-input" :class="{ error: v$.company_name.$errors }">
          <input type="text" :disabled="true" class="form-control" name="company_name" v-model="v$.company_name.$model">
        </div>
      </div>
      <div class="form-group mt--1 mb-3">
        <label class="auth-labels text-uppercase small pl-1">
          Country*
        </label>
        <div class="sr-custom-input">
          <input type="text" :disabled="true" class="form-control" name="company_name" :value="country">
        </div>
      </div>

      <div class="form-group mt--1 mb-3">
        <label class="auth-labels text-uppercase small pl-1">
          Booking Conditions*
        </label>

        <!-- <div class="sr-custom-input"> -->
        <div class="form">
          <input type="text" :disabled="edit === 'false' ? false : edit" class="form-control" name="company_name"
            :value="url_condition_value" v-bind:class="['input-edit', !edit && this.url_condition === this.url_condition_value && 'no-changes']" @input="event => this.url_condition_value = event.target.value">
          <button v-if="(!edit || edit === 'false') && this.url_condition !== this.url_condition_value" v-bind:class="['show-edit', 'btn-add-holiday']" class="btn btn-primary btn-save-holiday"
            @click="onEdit()">
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>
          </button>
          <button v-if="edit && this.url_condition_value.length > 0" v-bind:class="['show-edit', 'btn-add-holiday']" class="btn btn-primary btn-save-holiday"
            @click="onEdit()">
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>
          </button>
        </div>
      </div>
      <div class="form-group mt--1 mb-3" id="slim-cropper-wrapper">
        <div class="labels-wrapper">
          <label class="auth-labels text-uppercase small pl-1" for="slim">Company logo*</label>
          <div class="save-wrapper" v-if="imageChanged">
            <i class="far fa-save" @click="onSaveImage"></i>
          </div>
        </div>
        <slimCropper :options="slimOptions" class="slim-wrapper form-control w-100 d-flex p-4 h-auto bg-white">
          <div ref="preview" class="img-preview rounded-circle mb-2">
            <span v-if="!logo">A</span>
            <img v-else class="slim-result" :src="logo" alt="">
          </div>image.png
          <div class="d-flex flex-column ml-4">
            <div class="position-relative custom-input w-100">
              <input id="slim-image-container" type="file" accept="image/*" class="btn btn-primary" name="slim"
                ref="file">
              <label for="slim" class="file-uploader">
                <i class="far fa-2x fa-image mr-2"></i>
                <span>Upload or drop a file...</span>
              </label>
              <label for="slim" class="file-uploader-mobile">
                <i class="far fa-2x fa-image mr-2"></i>
              </label>
            </div>
            <small class="mt-3 file-helper w-100">
              For optimum results please select a JPG or PNG that is square in proportions and a minimum of 300 x 300px.
            </small>
          </div>
        </slimCropper>
      </div>
    </template>
  </modal>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import Modal from './Modal.vue'
/* import AutocompleteVue from './Autocomplete.vue' */
import Slim from '../vendor/slim/slim-custom.vue'
/* import CustomInputVue from './CustomInput.vue' */
import eventHub from '../utils/eventHub'
import Utils from '../utils/Utils'

export default {
  name: 'profileModal',
  props: ['openModal', 'modalName'],
  components: {
    modal: Modal,
    /*   autocomplete: AutocompleteVue, */
    slimCropper: Slim
    /*   customInput: CustomInputVue */
  },
  data () {
    var parsedData = null
    const userData = localStorage.getItem('userData')
    if (userData) {
      parsedData = JSON.parse(userData)
    }
    return {
      isMounted: false,
      token: localStorage.getItem('accessToken'),
      loggedUser: parsedData,
      company_name: parsedData ? parsedData.name : '',
      country: parsedData ? parsedData.country : '',
      savingCountry: false,
      url_condition: parsedData ? parsedData.url_condition : '',
      url_condition_value: parsedData ? parsedData.url_condition : '',
      logo: parsedData ? parsedData.logo : undefined,
      toSaveLogo: null,
      imageName: null,
      countries: null,
      edit: parsedData ? parsedData.url_condition === null ? false : parsedData.url_condition === '' ? 'false' : true : false,
      countryChanged: false,
      imageChanged: false,
      slimOptions: {
        ratio: '1:1',
        instantEdit: true,
        buttonEditLabel: '',
        initialImage: this.logo,
        size: '300, 300',
        maxFileSize: 0.3,
        didRemove: this.didRemove,
        didConfirm: this.assignFile,
        buttonRemoveLabel: "<i class='fas fa-times'></i>"
      }
    }
  },
  methods: {
    async onEdit () {
      await this.onSaveCondition()
    },
    async onSaveCondition () {
      if (this.edit) {
        this.edit = false
      } else {
        const that = this
        this.url_condition = this.url_condition_value
        if (this.url_condition_value === '') {
          this.edit = false
        } else {
          this.edit = true
        }
        var data = new FormData()
        data.append('_method', 'PUT')
        data.append('type', 'url_condition')
        data.append('user_id', this.loggedUser.id)
        data.append('url_condition', this.url_condition_value)
        this.axios.post(process.env.VUE_APP_API + '/profile/update', data, {
          headers: {

          }
        }).then((resp) => {
          if (resp?.status === 201 || resp?.status === 200) {
            this.$toast.success('Booking edited successfully!', {
              position: 'top-right',
              duration: 2000
            })
            const cropperWrapper = document.getElementById('slim-cropper-custom')
            if (cropperWrapper) {
              that.imageChanged = false
              cropperWrapper.classList.add('field-saved')
              setTimeout(() => {
                cropperWrapper.classList.remove('field-saved')
              }, 2500)
              that.axios.get(process.env.VUE_APP_API + '/profile/get', {
                headers: {

                }
              }).then(upResp => {
                if ((upResp?.status === 201 || upResp?.status === 200) && upResp?.data?.data) {
                  upResp.data.data.url_condition = this.url_condition_value
                  eventHub.$emit('user-data', { user: upResp.data.data })
                }
              }).catch((err) => {
                if (err?.response?.data?.message === 'Unauthenticated.') {
                  eventHub.$emit(this.modalName, false)
                  eventHub.$emit('logout', true)
                }
              })
            }
          }
        }).catch((err) => {
          that.imageChanged = false
          if (err?.response?.data?.message === 'Unauthenticated.') {
            eventHub.$emit(this.modalName, false)
            Utils.$handleHttpErrors(err)
          }
        })
      }
      console.log(this.edit)
      console.log(this.url_condition)
      console.log(this.url_condition_value)
    },
    didRemove (data) {
      this.$refs.preview.classList.add('mb-2')
      this.logo = undefined
    },
    async assignFile (e) {
      const that = this
      this.$refs.preview.classList.remove('mb-2')
      e.output.image.toBlob((blob) => {
        const newImage = URL.createObjectURL(blob)
        that.logo = newImage
        that.toSaveLogo = blob
        that.imageName = e.input.name
      })
    },
    async onSaveCountry () {
      const that = this
      const data = {
        type: 'country',
        country: this.country,
        user_id: this.loggedUser.id,
        _method: 'PUT'
      }
      const config = {
        method: 'POST',
        url: process.env.VUE_APP_API + '/profile/update',
        headers: {

        },
        data: data
      }
      this.axios(config).then(resp => {
        if (resp?.status === 200) {
          that.savingCountry = true
          const userData = JSON.parse(localStorage.getItem('userData'))
          userData.country = that.country
          localStorage.setItem('userData', JSON.stringify(userData))
          that.countryChanged = false
          setTimeout(() => {
            that.savingCountry = false
          }, 2500)
        }
      }).catch((err) => {
        that.countryChanged = false
        Utils.$handleHttpErrors(err)
      })
    },
    async onSaveImage () {
      const that = this
      var data = new FormData()
      data.append('_method', 'PUT')
      data.append('type', 'logo')
      data.append('user_id', this.loggedUser.id)
      data.append('url_condition', this.url_condition_value)
      data.append('logo', this.toSaveLogo, this.imageName)
      this.axios.post(process.env.VUE_APP_API + '/profile/update', data, {
        headers: {

        }
      }).then((resp) => {
        if (resp?.status === 201 || resp?.status === 200) {
          const cropperWrapper = document.getElementById('slim-cropper-custom')
          if (cropperWrapper) {
            that.imageChanged = false
            cropperWrapper.classList.add('field-saved')
            setTimeout(() => {
              cropperWrapper.classList.remove('field-saved')
            }, 2500)
            that.axios
              .get(process.env.VUE_APP_API + '/profile/get')
              .then(upResp => {
                if ((upResp?.status === 201 || upResp?.status === 200) && upResp?.data?.data) {
                  eventHub.$emit('user-data', { user: upResp.data.data })
                }
              })
              .catch((err) => {
                if (err?.response?.data?.message === 'Unauthenticated.') {
                  eventHub.$emit(this.modalName, false)
                  eventHub.$emit('logout', true)
                }
              })
          }
        }
      }).catch((err) => {
        that.imageChanged = false
        if (err?.response?.data?.message === 'Unauthenticated.') {
          eventHub.$emit(this.modalName, false)
          Utils.$handleHttpErrors(err)
        }
      })
    }
  },
  validations () {
    return {
      company_name: { required, minLength: minLength(2), maxLength: maxLength(100) }
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  created () {
    this.axios
      .get(process.env.VUE_APP_API + '/aux/countries', {})
      .then((response) => {
        this.countries = Object.values(response.data.data)
      }).catch(Utils.$handleHttpErrors)

    eventHub.$on('set-country', (value) => {
      if (value?.country) {
        this.country = value.country
      }
    })
  },
  watch: {
    openModal () {
      if (this.openModal) {
        this.token = localStorage.getItem('accessToken')
        const userData = localStorage.getItem('userData')
        if (userData) {
          const parsedData = JSON.parse(userData)
          this.company_name = parsedData.name
          this.country = parsedData.country
          this.logo = parsedData.logo
          this.url_condition = parsedData.url_condition
          this.url_condition_value = parsedData.url_condition
          this.edit = this.edit === 'false' ? false : this.edit
          this.loggedUser = parsedData
          setTimeout(() => {
            this.isMounted = true
          }, 150)
        }
      } else {
        this.company_name = ''
        this.country = ''
        this.url_condition = ''
        this.logo = undefined
        this.isMounted = false
      }
    },
    country (newValue, oldValue) {
      if (this.isMounted) {
        this.countryChanged = newValue !== '' && newValue !== oldValue
      }
    },
    logo (newValue, oldValue) {
      if (this.isMounted) {
        this.imageChanged = newValue !== '' && newValue !== undefined && newValue !== oldValue
      }
    }
  }
}
</script>

<style lang="css" scoped>
small.file-helper {
  font-size: 11px;
}

#slim-cropper.slim-wrapper {
  padding: 1.5rem 1.5rem 1rem;
}

.img-preview {
  margin-bottom: 0 !important;
}

.error-load-img {
  text-align: center;
  font-size: 16px;
  position: relative;
  bottom: -15px;
  color: #bf7461;
}

.form {
  display: flex;
  flex-direction: row;
}

.form>input {
  border: none;
  flex-grow: 2;
}

.form>button {
  background: royalblue;
  border: none;
  color: #fff;
  padding: 4px 10px;
}

.input-edit {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 44px;
  border: 1px solid #ced4da !important;
}

.show-edit {
  display: block;
  border-top-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 5px;
}

.no-changes {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.show-edit svg {
  fill: white;
  font-size: 22px;
}
</style>
