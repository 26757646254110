import eventHub from './eventHub'

const utils = {
    $timeWatch: null,
    $tokenInterval: null,
    $inactivityTime: 1000 * 60 * 10,
    $tolerance: 1000 * 60 * 2, // 120secs / 2min (1000msecs * 60secs * 2min)
    $startRefreshTime: new Date().getTime(),
    $lastActivityTime: new Date().getTime(),
    $appTimeout: null,
    $resetTime: () => {
        clearTimeout(utils.$timeWatch)
        utils.$lastActivityTime = new Date().getTime()
        utils.$timeWatch = setTimeout(() => {
            eventHub.$emit('logout', true)
        }, utils.$inactivityTime)
        // 5 min with inactivity app will logout
    },
    $refreshTokenWatcher: () => {
        utils.$tokenInterval = setInterval(() => {
            const timeNow = new Date().getTime()
            const timeDiff = (timeNow - utils.$lastActivityTime) / 1000
            if ((utils.$startRefreshTime + utils.$appTimeout) - utils.$tolerance <= timeNow) {
                // inactivity has reached the limit
                const reachLimit = timeDiff > ((utils.$inactivityTime - utils.$tolerance) / 1000)
                eventHub.$emit(
                    reachLimit ? 'logout' : 'refresh-token',
                    true
                )
            }
        }, 1000 * 15)
    },
    $stopWatchInactivity: () => {
        clearTimeout(utils.$timeWatch)
        clearInterval(utils.$tokenInterval)
        document.onmousemove = null
        document.onclick = null
        document.onmousewheel = null
        document.onkeydown = null
    },
    $startWatchInactivity: () => {
        const strTimeout = localStorage.getItem('sf@timeout')
        if (strTimeout) utils.$appTimeout = parseInt(strTimeout) * 1000

        const timeNow = new Date().getTime()
        utils.$startRefreshTime = timeNow
        utils.lastActivityTime = timeNow

        utils.$refreshTokenWatcher()

        document.onmousemove = (event) => {
            utils.$resetTime()
        }

        document.onclick = (event) => {
            utils.$resetTime()
        }

        document.onmousewheel = (event) => {
            utils.$resetTime()
        }

        document.onkeydown = (event) => {
            utils.$resetTime()
        }
    },
    $handleHttpErrors: async (error) => {
        if (error?.response?.data?.message === 'Unauthenticated.') {
            return eventHub.$emit('logout', true)
        }
        console.log('Error: ', error)
        return { ok: false, error }
    },
    $handleAccessErrors: async (error) => {
        if (error?.response?.data?.message === 'Unauthenticated.') {
            return eventHub.$emit('logout', true)
        }
        if (error?.response?.data && error?.response?.data?.msg === 'Invalid data.') {
            return { ok: false, error: error.response.data.data }
        }
        return { ok: false, error }
    }
}

export default utils
