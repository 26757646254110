<template>
<!-- <div class="w-100 h-100">
  <div id="sf-header" class="s-header d-flex shadow bg-white position-relative z-index"> -->
    <!-- <template v-if="!isAuthenticated">
      <div class="home-tabs"></div>
      <div class="d-flex header-tools">
        <router-link class="auths-text" :to="{ name: 'signup', params: {} }">Sign up</router-link>
        <span class="mx-2">/</span>
        <router-link class="auths-text" :to="{ name: 'login', params: {} }">Log in</router-link>
      </div>
    </template> -->
    <template v-if="isAuthenticated">
    <!-- <div class="w-100 h-100"> SF2807-->
    <div id="sf-header" class="s-header d-flex shadow bg-white position-relative z-index">
    <div class="header-title-link">
      <router-link class="text-uppercase font-weight-bold mr-auto text-dark" :to="{ name: 'home', params: {} }">
        <div class="header-title-logo d-none d-lg-block"></div>
        <div class="header-title-logo small d-lg-none"></div>
      </router-link>
    </div>
      <div class="home-tabs">
        <button id="holidays" class="tablinks" :class="activeTab == 'holidays' ? 'active-tab' : ''" @click="onTabClicked">
          <i class="fas fa-paper-plane"></i>
          Holidays
        </button>
        <button id="access-view" class="tablinks" v-if="user.role != 'SuperAdmin'" :class="activeTab == 'access-view' ? 'active-tab' : ''" @click="onTabClicked">
          <i class="fas fa-users"></i>
          {{ user && user?.role.toLowerCase() === 'operator' ? 'Agents' : 'Operators' }}
        </button>
        <!--  <button v-if="user.role === 'SuperAdmin'" id="activity" class="tablinks" :class="activeTab == 'activity' ? 'active-tab' : ''" @click="onTabClicked">
          <i class="fas fa-tasks"></i>
           // {{ user && user?.role.toLowerCase() === 'operator' ? 'Agents' : 'Operators' }}
          Activity
        </button> -->
         <button v-if="user.role === 'SuperAdmin'" id="usersList" class="tablinks" :class="activeTab == 'usersList' ? 'active-tab' : ''" @click="onTabClicked">
          <i class="fa fa-users"></i>
          <!-- {{ user && user?.role.toLowerCase() === 'operator' ? 'Agents' : 'Operators' }} -->
          Users List
        </button>
        <button v-if="user.role === 'SuperAdmin'" id="currencies" class="tablinks" :class="activeTab == 'currencies' ? 'active-tab' : ''" @click="onTabClicked">
          <i class="fa fa-gbp"></i>
          <!-- {{ user && user?.role.toLowerCase() === 'operator' ? 'Agents' : 'Operators' }} -->
          Currencies
        </button>
        <button v-if="user.role === 'SuperAdmin'" id="logslist" class="tablinks" :class="activeTab == 'logslist' ? 'active-tab' : ''" @click="onTabClicked">
          <!-- {{ user && user?.role.toLowerCase() === 'operator' ? 'Agents' : 'Operators' }} -->
          Logs list
        </button>
      </div>
      <div class="d-flex header-tools">
        <div class="dropdown" id="notf-dropdown">
          <i id="notif-dropdown-btn" class="far fa-bell mr-3" @click="onClickNotifications">
            <span class="badge" v-if="notifications > 0">{{ notifications }}</span>
          </i>
          <div class="dropdwn-content">
            <div class="notf-dp-title">NOTIFICATIONS</div>
            <div class="notf-dp-body">
              <NotificationItems></NotificationItems>
            </div>
          </div>
        </div>
        <div class="dropdown" id="profile-dropdown">
          <img id="profile-dropdown-btn" class="dropdown-btn" :src="user && user.logo ? user.logo : emptyImage"  @click="onClickProfileDP">
          <div class="dropdwn-content">
            <div class="dp-option" @click="openEditProfile">
              <i class="far fa-user-circle"></i>
              <span>Operator Profile</span>
            </div>
            <div class="dp-option" @click="openAccountSettings">
              <i class="fas fa-cog"></i>
              <span>Account Settings</span>
            </div>
            <div class="dp-option" @click="openCalendar">
              <i class="fas fa-calendar"></i>
              <span>Calendar</span>
            </div>
            <div v-if="user.role !== 'SuperAdmin'" class="dp-option" @click="openEditDesign">
              <i class="fas fa-cog"></i>
              <span>Edit Design</span>
            </div>
            <div class="dp-option" @click="logout">
              <i class="fas fa-sign-out-alt"></i>
              <span>Logout</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    </template>
    <router-view v-slot="{ Component }">
    <transition name="page" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
  <profileModal :openModal="editProfile" modalName="profile-modal"></profileModal>
  <settingsModal :openModal="showSettings" modalName="settings-modal"></settingsModal>
  <calendarModal :openModal="showCalendar" modalName="calendar-modal"></calendarModal>
  <designModal :openModal="editDesign" modalName="design-modal"></designModal>
  <footer class="p-2 text-center w-100 bg-white">
    <small>© SureFooted</small>
  </footer>
</template>

<script>
import eventHub from './utils/eventHub'
import Settings from './components/Settings.vue'
import Calendar from './components/Calendar.vue'
import Profile from './components/Profile.vue'
import Template from './components/Template.vue'
import NotificationItems from './components/common/Notification-items.vue'
import DesignModal from './components/Design.vue'

export default {
  name: 'app',
  props: [],
  components: {
    settingsModal: Settings,
    calendarModal: Calendar,
    profileModal: Profile,
    designModal: DesignModal,
    Template,
    NotificationItems
  },
  data () {
    return {
      emptyImage: './assets/img/profile.png',
      user: undefined,
      showSettings: false,
      showCalendar: false,
      isAuthenticated: false,
      editProfile: false,
      editDesign: false,
      activeTab: 'holidays',
      notifications: 0
    }
  },
  methods: {
    onClickProfileDP (event) {
      const dpElem = document.getElementById('profile-dropdown')
      if (dpElem) {
        dpElem.classList.toggle('dp-opened')
      }
    },
    onClickNotifications (event) {
      const dpElem = document.getElementById('notf-dropdown')
      if (dpElem) {
        dpElem.classList.toggle('dp-opened')
      }
    },
    logout () {
      eventHub.$emit('logout', true)
    },
    openEditProfile (event) {
      eventHub.$emit('profile-modal', true)
    },
    openAccountSettings () {
      eventHub.$emit('settings-modal', true)
    },
    openCalendar () {
      eventHub.$emit('calendar-modal', true)
    },
    openEditDesign () {
      eventHub.$emit('design-modal', true)
    },
    profileListener (event) {
       const profileElement = document.getElementById('profile-dropdown-btn')
       if (profileElement && event.target) {
        if (!profileElement.contains(event.target)) {
          const dpElem = document.getElementById('profile-dropdown')
          if (dpElem) {
            dpElem.classList.remove('dp-opened')
          }
        }
      }
    },
    notificationListener (event) {
      const notificationElement = document.getElementById('notif-dropdown-btn')
      if (notificationElement && event.target) {
        if (!notificationElement.contains(event.target)) {
          const dpNotElem = document.getElementById('notf-dropdown')
          if (dpNotElem) {
            dpNotElem.classList.remove('dp-opened')
          }
        }
      }
    },
    onTabClicked (event) {
      if (event?.target?.id) {
        console.log(event.target.id)
        this.activeTab = event.target.id
      }
      eventHub.$emit('selected-tab', this.activeTab)
    }
  },
  created () {
    if (localStorage.getItem('accessToken')) {
      this.isAuthenticated = true
    }

    if (localStorage.getItem('userData')) {
      const user = JSON.parse(localStorage.getItem('userData'))
      this.user = user
    }

    this.axios
      .get(process.env.VUE_APP_API + '/aux/countries', {})
      .then((response) => {
        if (response?.data?.data) {
          this.countries = Object.values(response.data.data)
        }
      })

    eventHub.$on('set-notifications', (items) => {
      if (items && items.length) this.notifications = items.length
    })
  },
  mounted () {
    eventHub.$on('login', (e) => {
      this.user = e.user
      if (e.access_token) {
        localStorage.setItem('accessToken', e.access_token)
        this.isAuthenticated = true
      }
    })

    eventHub.$on('logout', (e) => {
      if (e) {
        delete this.axios.defaults.headers.common.authorization

        this.notifications = 0
        this.user = undefined
        this.isAuthenticated = false
        localStorage.removeItem('userData')
        localStorage.removeItem('accessToken')
        localStorage.removeItem('sf@timeout')
        this.activeTab = 'holidays'
        this.$router.push('/')
      }
    })

    eventHub.$on('user-data', (e) => {
      localStorage.setItem('userData', JSON.stringify(e.user))
      localStorage.setItem('sf@timeout', e?.expires_in?.toString() || 3600)
      this.user = e.user
    })

    eventHub.$on('profile-modal', (e) => {
      this.editProfile = e
    })

    eventHub.$on('settings-modal', (e) => {
      this.showSettings = e
    })

    eventHub.$on('calendar-modal', (e) => {
      this.showCalendar = e
    })

    eventHub.$on('design-modal', (e) => {
      this.editDesign = e
    })

    eventHub.$on('close-design', () => {
      this.editDesign = false
    })

    document.addEventListener('click', this.profileListener)
    document.addEventListener('click', this.notificationListener)
  },
  unmounted () {
    document.removeEventListener('click', this.profileListener)
    document.removeEventListener('click', this.notificationListener)
  }
}
</script>

<style scoped>
  .s-header {
    min-height: 7.5vh;
    padding: 0 1.5rem;
  }
  .s-header--hidden {
    display: none !important;
  }
  .s-header a.text-dark {
    font-size: 18px;
  }
  .s-header a.auths-text {
    font-size: 17px;
    color: #7E84A3;
  }
  .s-header a.auths-text:hover {
    color: #614fe8;
  }
  .header-title-link {
    position: absolute;
  }
  .header-title-logo {
    width: 175px;
    height: 32px;
    background-image: url('assets/img/surefooted-big.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .header-title-logo.small{
    background-image: url('assets/img/surefooted-small.svg') !important;
    background-position: left;
  }

  .d-flex {
    align-items: center;
  }
  .dropdown {
    display: inline-block;
  }
  .dropdown-btn {
    width: 50px;
    height: 50px;
    border: 1px solid #cecece;
    border-radius: 50%;
    cursor: pointer;
  }
  .dropdwn-content {
    display: none;
    position: absolute;
    background-color: #fff;
    font-weight: 100;
    min-width: 170px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    right: 0;
    border-radius: 10px;
    padding: 5px 2px;
  }
  #notf-dropdown .dropdwn-content {
    padding: 10px 10px 5px;
    border-radius: 5px;
  }
  #notf-dropdown .dropdwn-content .notf-dp-title {
    font-size: 11px;
    font-weight: bold;
    color: #a8a8a8;
    margin-bottom: 12px;
  }
  #notf-dropdown .dropdwn-content .notf-dp-body {
    min-width: 14vw;
    max-height: 320px;
    overflow: scroll;
  }
  .dp-option {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 6px 10px;
  }
  .dp-option:hover {
    background-color: #eff2f7;
  }
  .dp-option span {
    font-size: 14px;
    color: #5e616a;
  }
  .dp-option i {
    color: #5e616a;
    font-size: 18px;
    margin-top: 1px;
    margin-right: 4px;
  }
  .dropdown.dp-opened .dropdwn-content {
    display: block;
  }
  .far.fa-bell {
    font-size: 20px;
    color: #614fe8;
    cursor: pointer;
    position: relative;
  }
  .home-tabs {
    flex: 1;
    height: 100%;
    text-align: center;
  }
  .tablinks {
    background-color: inherit;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 17px;
    min-height: 7.1vh;
    padding: 0 5px;
    margin: 0 20px;
    position: relative;
    top: 2px;
  }
  .tablinks i {
    margin-right: 5px;
  }
  #notif-dropdown-btn .badge {
    position: absolute;
    top: -6px;
    right: -5px;
    background-color: #604fe8;
    border-radius: 50%;
    color: #fff;
    font-size: 10px;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 4px;
  }
  .tablinks.active-tab {
    color: #614fe8;
    border-bottom: 0.5vh solid #614fe8;
  }

  .fa-paper-plane, .fa-users {
    pointer-events: none;
  }
</style>
